export function isomorphicMap(array, callback) {
    if (!array || !array.length)
        return;
    const newArray = [];
    const x = array.length;
    for (let i = 0; i < x; i++) {
        const counter = callback(array[i], i);
        newArray.push(counter);
    }
    return newArray;
}
///server side rendering engine (Clearscript) is poorly handling list arrays for some reason
///so we need to have an extra layer making a simple array from it so no SSR errors are happening
///here we just make array from property list with same values. This works both on server and client side
///this method is used in buttons since on server side we use link list with single item for it (to make anchors work)
///so it is ok to pick first item always to get single item
export function extractSingleLinkFromList(array) {
    const footerLinksArray = Array();
    array.forEach(item => {
        footerLinksArray.push({
            target: item.target,
            href: item.href,
            title: item.title,
        });
    });
    return footerLinksArray[0];
}
export function getScrollBarWidth() {
    if (typeof document === "undefined" || !document)
        return 0;
    return window.innerWidth - document.documentElement.clientWidth;
}
export default isomorphicMap;
