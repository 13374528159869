import React from "react";
import { Box, Divider, Link, ListItemText, makeStyles } from "@material-ui/core";
import Item from "./Item";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { usePageContext } from "../Context/PageContextProvider";
import { LocalizedMenuAfterWord } from "../../enums";
const useStyles = makeStyles((theme) => ({
    active: {
        textDecoration: "underline",
        textDecorationColor: theme.colorCard?.inputText || theme.palette?.text?.primary,
    },
    secondLevel: {
        color: "#000000",
        fontWeight: 600,
        fontSize: "1.125rem",
        margin: 0,
        ...theme.customStyling?.NavigationItems?.secondLevel,
    },
    thirdLevel: {
        color: "#333333",
        margin: 0,
        ...theme.customStyling?.NavigationItems?.thirdLevel,
    },
    navContainer: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxHeight: 550,
        maxWidth: 900,
    },
    itemBox: {
        display: "flex",
        marginTop: 24,
        flexDirection: "column",
    },
    rootLink: {
        margin: 30,
        marginLeft: 0,
        display: "inline-block",
    },
    icon: {
        paddingLeft: 8,
    },
    collapseWrapper: {
        padding: 40,
        paddingTop: 0,
        ...theme.customStyling?.MegaMenu?.collapseWrapper,
    },
    linkWithIcon: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        fontSize: "1.25rem",
        ...theme.customStyling?.MegaMenu?.rootLink,
    },
    linkIcon: {
        marginLeft: 8,
        fontSize: "1rem",
        ...theme.customStyling?.MegaMenu?.linkIcon,
    },
    divider: {
        ...theme.customStyling?.MegaMenu?.divider,
    },
}));
const MegaMenu = (props) => {
    const classes = useStyles();
    const { startPageData } = usePageContext();
    const countryAfterWord = LocalizedMenuAfterWord[startPageData.language.name] || LocalizedMenuAfterWord["default"];
    const menuTitle = props.menu.CustomMenuTitle || `${props.menu.Name} ${countryAfterWord}`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: classes.collapseWrapper, onKeyDownCapture: props.handleKeyPress },
            React.createElement(Box, { onClick: () => props.handleOpen(), className: classes.rootLink },
                React.createElement(Link, { href: props.menu.Link, className: classes.linkWithIcon },
                    menuTitle,
                    React.createElement(ArrowForwardIosIcon, { className: classes.linkIcon }))),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement(Box, { className: classes.navContainer }, props.menu.Children &&
                props.menu.Children.map((item, index) => (React.createElement(Box, { className: classes.itemBox, key: index },
                    React.createElement(Item, { link: item.Link, key: index, onClick: () => props.handleOpen() },
                        React.createElement(ListItemText, { disableTypography: true, className: classes.secondLevel, primary: item.Name, onClick: () => props.handleOpen() })),
                    item.Children &&
                        item.Children.map((x, i) => (React.createElement(Item, { link: x.Link, key: i, onClick: () => props.handleOpen() },
                            React.createElement(ListItemText, { disableTypography: true, className: classes.thirdLevel, primary: x.Name, onClick: () => props.handleOpen() })))))))))));
};
export default MegaMenu;
