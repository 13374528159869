export const getHashElement = () => {
    const { hash } = window.location;
    const elementId = hash?.split("#")[1];
    return hash ? document.getElementById(elementId) ?? undefined : undefined;
};
export const scrollToHashElement = () => {
    let retries = 0;
    const scrollToElement = () => {
        if (retries > 5)
            return;
        const hashElement = getHashElement();
        if (hashElement && hashElement?.scrollIntoView) {
            hashElement.scrollIntoView({ behavior: "smooth" });
            return;
        }
        else {
            setTimeout(() => scrollToElement(), 100);
        }
        retries++;
    };
    scrollToElement();
};
