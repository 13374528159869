import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#EFDF00",
    secondary: "#EFDF00",
    background: "#F2F2F2",
    cardBackground: "#FFFFFF",
    tableBackground: "#EBEBEB",
    background2: "#FFFFFF",
    accordionClosed: "#EBEBEB",
    tableOutline: "#D9D9D6",
    dividerLine: "#D9D9D6",
    secondaryHover: "#F8EB4C",
    background3: "#F2F2F2",
    wcagOutline: "#808080",
    inputOutline: "#8C8C8B",
    placeholderText: "#8C8C8B",
    wcag: "#8C8C8B",
    gdprText: "#656666",
    helperText: "#656666",
    primaryHover: "#656666",
    tetriaryHover: "#656666",
    subhead: "#3E3F40",
    bodyText: "#3E3F40",
    headlines: "#1A1A1A",
    label: "#1A1A1A",
    inputText: "#1A1A1A",
    success: "#40D37C",
    successBackground: "#E0F8EA",
    info: "#1FA2C8",
    infoBackground: "#DBF0F6",
    warning: "#F7A84B",
    warningBackground: "#FEF1E2",
    error: "#EE5352",
    errorBackground: "#FCE3E3",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const renaultforetag = {
    themeName: "renaultforetag",
    colorCard: colorCard,
    brand: BrandsEnum.RenaultForetag,
    shape: {
        borderRadius: 0,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: "underline",
            },
            tetriaryLink: {
                textDecoration: "underline",
            },
            textArea: {
                a: {
                    color: colorCard.subhead,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                    "&:active": {
                        color: colorCard.primaryHover,
                    },
                },
                h1: {
                    color: colorCard.headlines,
                },
                h2: {
                    color: colorCard.headlines,
                },
                h3: {
                    color: colorCard.headlines,
                },
                h4: {
                    color: colorCard.headlines,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            primaryButton: {
                backgroundColor: colorCard.primary,
                borderColor: colorCard.primary,
                color: colorCard.headlines,
                "&:hover": {
                    BackgroundColor: colorCard.secondaryHover,
                },
            },
            secondaryButton: {
                borderColor: colorCard.headlines,
                backgroundColor: colorCard.headlines,
                color: colorCard.cardBackground,
                "&:hover": {
                    backgroundColor: colorCard.subhead,
                },
            },
        },
        ArticleTeaser: {
            link: {
                "& .MuiButton-outlined": {
                    borderColor: colorCard.headlines,
                    backgroundColor: colorCard.headlines,
                    color: colorCard.cardBackground,
                    "&:hover": {
                        backgroundColor: colorCard.subhead,
                        borderColor: colorCard.subhead,
                    },
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.headlines,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontFamily: GetFontFamily("NouvelR-Normal"),
            },
            icon: {
                display: "block",
                fill: colorCard.label,
            },
        },
        MegaMenu: {
            linkIcon: {
                fill: colorCard.label,
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
                fontFamily: GetFontFamily("NouvelR-Normal"),
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.dividerLine,
            },
            breadCrumbsBox: {
                "& .MuiLink-underlineHover": {
                    "& .MuiSvgIcon-root": {
                        fill: colorCard.subhead,
                    },
                },
                "& a": {
                    color: colorCard.subhead,
                },
            },
        },
        ContactUsAreaTabHead: {
            tabsWrapper: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.tableOutline}`,
                borderBottom: `1px solid ${colorCard.tableOutline}`,
            },
        },
        AccordionBlockContainer: {
            root: {
                borderLeft: `1px solid ${colorCard.tableOutline}`,
                borderRight: `1px solid ${colorCard.tableOutline}`,
            },
        },
        CardInformationBlock: {
            darkModeTextColor: colorCard.cardBackground,
            headerwrapper: {
                backgroundColor: colorCard.headlines,
                color: colorCard.primary,
            },
            header: {
                color: colorCard.primary,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.cardBackground,
                },
            },
            innerBody: {
                color: colorCard.tableBackground,
            },
            link: {
                "& a": {
                    color: colorCard.cardBackground,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.headlines,
            },
        },
        infoBody: {
            a: {
                color: colorCard.subhead,
            },
        },
        CustomizedTextBox: {
            textBoxInput: {
                backgroundColor: colorCard.cardBackground,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        greencard: {
            gdprWrapper: {
                "& a": {
                    color: colorCard?.gdprText,
                },
            },
        },
        Header: {
            menuBarWrapper: {
                height: 73,
            },
            headerBlock: {
                backgroundColor: colorCard.headlines,
                color: colorCard.cardBackground,
                borderBottom: `1px solid ${colorCard?.subhead}`,
            },
            divider: {
                height: "0px",
            },
            drawerHeader: {
                backgroundColor: colorCard.headlines,
                justifyContent: "left",
                PaddingLeft: "20px",
            },
            toolbar: {
                backgroundColor: colorCard.headlines,
                borderBottom: `1px solid ${colorCard?.subhead}`,
            },
            links: {
                borderRight: "0px",
            },
            link: {
                color: colorCard.cardBackground,
            },
            menuButtonText: {
                color: colorCard.cardBackground,
            },
        },
        Navigation: {
            appmenu: {
                backgroundColor: colorCard.cardBackground,
                [theme.breakpoints.up("md")]: {
                    backgroundColor: colorCard.headlines,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "auto",
                },
            },
            menuButtonText: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
                color: colorCard.cardBackground,
            },
        },
        Item: {
            link: {
                color: colorCard.headlines,
                fontFamily: GetFontFamily("NouvelR-Regular"),
            },
            active: {
                textDecoration: "underline",
                fontFamily: GetFontFamily("NouvelR-Regular"),
                color: colorCard.headlines,
            },
        },
        NavigationItems: {
            mobMenuItem: {
                color: colorCard.headlines,
                "& :hover": {
                    textDecoration: "none",
                    cursor: "pointer",
                    backgroundColor: colorCard.tableOutline,
                },
                "&.active": {
                    textDecoration: "underline",
                },
            },
            link: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
                color: colorCard.headlines,
                [theme.breakpoints.up("md")]: {
                    color: colorCard.cardBackground,
                },
                "&.active": {
                    textDecoration: "none",
                },
            },
            text: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
                color: colorCard.headlines,
                [theme.breakpoints.up("md")]: {
                    color: colorCard.cardBackground,
                },
                "&.active": {
                    color: colorCard?.primary,
                    textDecoration: "none",
                },
            },
            activeItem: {},
            thirdLevel: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
            },
            secondLevel: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
            },
        },
        heroWithHeadlineBody: {
            primaryButton: {
                "&:hover": {
                    backgroundColor: colorCard.secondaryHover,
                },
            },
        },
        CallbackBlock: {
            gdprArea: {
                "& a": {
                    color: colorCard?.gdprText,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                        textDecoration: "none",
                    },
                },
            },
        },
        ContactUsAreaTabBody: {
            callbackItemsRow: {
                "& .MuiFormLabel-root.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "& .MuiFormLabel-root.Mui-error": {
                    color: colorCard?.error,
                },
            },
            formItemsRow: {
                "& .text-area .button-primary": {
                    "&:hover": {
                        borderColor: colorCard.secondaryHover,
                        backgroundColor: colorCard.secondaryHover,
                    },
                },
            },
        },
        TextBlock: {
            textBlock: {
                ".text-area a": {
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                    "&:active": {
                        color: colorCard.primaryHover,
                    },
                },
                "& .text-area .button-primary": {
                    color: colorCard.headlines,
                    fontFamily: GetFontFamily("NouvelR-bold"),
                    "&:hover": {
                        fontFamily: GetFontFamily("NouvelR-bold"),
                        color: colorCard.headlines,
                        backgroundColor: colorCard.secondaryHover,
                        borderColor: colorCard.secondaryHover,
                    },
                    ":focus": {
                        fontFamily: GetFontFamily("NouvelR-bold"),
                        color: colorCard.headlines,
                        backgroundColor: colorCard.secondaryHover,
                        borderColor: colorCard.secondaryHover,
                    },
                },
            },
        },
        WebshopEntryBlock: {
            gdprLinkHoverColor: colorCard?.secondaryHover,
            fullBackgroundWrapper: {
                "& .MuiFormLabel-root.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "& .MuiFormLabel-root.Mui-error": {
                    color: colorCard?.error,
                },
            },
            withoutBackground: {
                borderBottom: `1px solid ${colorCard?.dividerLine}`,
                "& .MuiFormLabel-root.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "& .MuiFormLabel-root.Mui-error": {
                    color: colorCard?.error,
                },
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.tableOutline,
            },
            wrapper: {
                borderBottom: `1px solid ${colorCard?.subhead}`,
                backgroundColor: colorCard.headlines,
                "& .MuiTypography-h4": {
                    color: colorCard.cardBackground,
                },
                "& .MuiDivider-root": {
                    background: colorCard.subhead,
                },
                "& .MuiLink-root": {
                    color: colorCard.cardBackground,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.headlines,
                },
            },
            footerBottomWrapper: {
                background: colorCard.headlines,
                "& .MuiLink-root": {
                    color: colorCard.cardBackground,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.headlines,
                },
            },
        },
        SubmitButtonElementBlock: {
            submitButton: {
                backgroundColor: colorCard.headlines,
            },
        },
    },
    textBlock: {
        tableBackground: colorCard.tableBackground,
        linkHoverColor: colorCard.primaryHover,
    },
    teaser: {
        root: {
            backgroundColor: colorCard.cardBackground,
            border: colorCard.cardBackground,
        },
        container: {
            backgroundColor: colorCard.background,
            border: colorCard.cardBackground,
        },
    },
    webshopEntry: {
        gdprTextColor: colorCard.gdprText,
        backgroundColor: colorCard.tableOutline,
        inputFieldColor: colorCard.cardBackground,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.headlines,
        textColor: colorCard.background3,
        body: {
            button: {
                primary: {
                    color: colorCard.headlines,
                    borderColor: colorCard.primary,
                    backgroundColor: colorCard.primary,
                    "& :hover": {
                        backgroundColor: colorCard.secondaryHover,
                        color: colorCard.headlines,
                    },
                },
                secondary: {
                    color: colorCard.headlines,
                    borderColor: colorCard.cardBackground,
                    backgroundColor: colorCard.cardBackground,
                    "& :hover": {
                        backgroundColor: colorCard.subhead,
                        color: colorCard.headlines,
                    },
                },
            },
        },
    },
    header: {
        uspBannerBackground: colorCard.primary,
        uspBannerTextColor: colorCard.headlines,
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.headlines,
        color: colorCard.headlines,
        backgroundColor: colorCard.headlines,
        divider: colorCard.dividerLine,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
        darkModeTextColor: colorCard.background,
    },
    layout: {
        backgroundColor: colorCard.background,
    },
    accordionBlock: {
        expandedBackgroundColor: colorCard.cardBackground,
        backgroundColor: colorCard.accordionClosed,
    },
    contactUs: {
        backgroundColor: colorCard.accordionClosed,
        activeBackgroundColor: colorCard.tableOutline,
    },
    factAndIcon: {
        backgroundColor: colorCard.cardBackground,
    },
    breadcrumbs: {
        activeColor: colorCard.headlines,
        activeFontFamily: GetFontFamily("NouvelR-Bold"),
        color: colorCard.subhead,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
        headerColor: colorCard.background2,
    },
    tooltip: {
        backgroundColor: colorCard?.headlines,
    },
    icons: {
        expandIcon: (React.createElement("svg", { width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.1001 17.9836C8.26025 14.6817 6.65014 12.5128 4 8.47089L5.65633 7.33783C7.97617 10.9355 9.39657 12.8577 11.9995 15.9631C14.6034 12.8086 16.0229 10.8864 18.3899 7.2887L20 8.47089C17.3489 12.5128 15.7388 14.6817 12.8989 17.9836H11.1001Z", fill: "#1A1A1A" }))),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734V22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036V12.4734H1.39634C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266H11.5266V1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962429 12.355 1.04132C12.4339 1.12022 12.4734 1.23857 12.4734 1.39636V11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#141414" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.1001 17.9836C8.26025 14.6817 6.65014 12.5128 4 8.47089L5.65633 7.33783C7.97617 10.9355 9.39657 12.8577 11.9995 15.9631C14.6034 12.8086 16.0229 10.8864 18.3899 7.2887L20 8.47089C17.3489 12.5128 15.7388 14.6817 12.8989 17.9836H11.1001Z", fill: "#1A1A1A" }))),
        primaryButtonIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M20 12.2791C17.1175 14.6094 14.667 16.3874 11.1115 18.7176L10.4869 17.877C13.2736 16.099 15.4118 14.6094 18.3427 12.3754H4V11.3422H18.3906C15.4597 9.10825 13.3215 7.61861 10.5348 5.84061L11.1115 5C14.667 7.33026 17.1175 9.10825 20 11.4385V12.2791Z", fill: "#1A1A1A", stroke: "#1A1A1A", "stroke-width": "0.4" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.7088 22H10.1958C7.72337 18.6421 5.21417 14.7303 3 10.5609L4.36551 9.74867C6.4318 13.587 8.64597 17.092 10.8969 20.2291C14.5502 13.9923 17.4283 8.49426 20.528 2L21.967 2.7004C18.646 9.6751 15.6942 15.2843 11.7088 22Z", fill: "#1A1A1A", stroke: "#1A1A1A", "stroke-width": "0.4" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { opacity: "0.4" },
                React.createElement("path", { d: "M4 19.0797L11.0945 11.9852L4 4.89013L4.89073 4L11.9852 11.0945L19.0797 4L19.9704 4.86054L12.8759 11.9852L20 19.1093L19.1099 20L11.9852 12.8753L4.89073 19.9704L4 19.0797Z", fill: "#1A1A1A" })))),
        tooltipCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 19.0797L11.0945 11.9852L4 4.89013L4.89073 4L11.9852 11.0945L19.0797 4L19.9704 4.86054L12.8759 11.9852L20 19.1093L19.1099 20L11.9852 12.8753L4.89073 19.9704L4 19.0797Z", fill: "#F2F2F2" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7088 22H10.1958C7.72337 18.6421 5.21417 14.7303 3 10.5609L4.36551 9.74867C6.4318 13.587 8.64597 17.092 10.8969 20.2291C14.5502 13.9923 17.4283 8.49426 20.528 2L21.967 2.7004C18.646 9.6751 15.6942 15.2843 11.7088 22Z"
          fill="#1A1A1A"
          stroke="#1A1A1A"
          stroke-width="0.4"
        />
      </svg>
`),
        heroCheckmarkIconCSS: encodeURIComponent(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7088 22H10.1958C7.72337 18.6421 5.21417 14.7303 3 10.5609L4.36551 9.74867C6.4318 13.587 8.64597 17.092 10.8969 20.2291C14.5502 13.9923 17.4283 8.49426 20.528 2L21.967 2.7004C18.646 9.6751 15.6942 15.2843 11.7088 22Z" fill="#EFDF00" stroke="#EFDF00" stroke-width="0.4"/>
</svg>`),
        bulletIconCSS: encodeURIComponent(`
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7088 22H10.1958C7.72337 18.6421 5.21417 14.7303 3 10.5609L4.36551 9.74867C6.4318 13.587 8.64597 17.092 10.8969 20.2291C14.5502 13.9923 17.4283 8.49426 20.528 2L21.967 2.7004C18.646 9.6751 15.6942 15.2843 11.7088 22Z" fill="#1A1A1A" stroke="#1A1A1A" stroke-width="0.4"/>
</svg>
`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { opacity: "0.4" },
                React.createElement("path", { d: "M4 19.0797L11.0945 11.9852L4 4.89013L4.89073 4L11.9852 11.0945L19.0797 4L19.9704 4.86054L12.8759 11.9852L20 19.1093L19.1099 20L11.9852 12.8753L4.89073 19.9704L4 19.0797Z", fill: "#1A1A1A" })))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { "clip-path": "url(#clip0_1118_200)" },
                React.createElement("path", { d: "M11.5295 22C5.81172 22 2 17.7054 2 12.0129C2 6.32043 5.81172 2 11.5295 2C17.2479 2 21.0596 6.32043 21.0596 12.0129C21.0596 17.7054 17.2479 22 11.5295 22ZM11.5295 21.0089C16.6125 21.0089 20.0179 17.1466 20.0179 12.0129C20.0179 6.85395 16.6125 2.99106 11.5295 2.99106C6.47242 2.99106 3.04172 6.85395 3.04172 12.0129C3.04172 17.1466 6.47242 21.0089 11.5295 21.0089ZM8.40387 6.57478V5.58321C9.49677 5.27871 10.4878 5.15153 11.4029 5.15153C13.4863 5.15153 14.8336 6.24391 14.8336 8.32787C14.8336 9.52262 14.3254 10.234 13.2832 11.3016L12.8256 11.7586C12.1649 12.4705 12.0377 12.9021 12.0377 13.5375V14.6557H10.9454V13.4868C10.9454 12.5718 11.0979 11.9876 12.0636 10.9712L12.5206 10.5137C13.385 9.59861 13.6388 9.2176 13.6388 8.3532C13.6388 6.82862 12.7496 6.19325 11.1739 6.19325C10.386 6.19325 9.36959 6.32043 8.40387 6.57478ZM10.615 17.7054C10.615 17.1207 10.92 16.8669 11.5042 16.8669C12.1396 16.8669 12.3939 17.1207 12.3939 17.7054C12.3939 18.2901 12.1396 18.5693 11.5042 18.5693C10.92 18.5693 10.615 18.2901 10.615 17.7054Z", fill: "#1A1A1A" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_1118_200" },
                    React.createElement("rect", { width: "19.0596", height: "20", fill: "white", transform: "translate(2 2)" }))))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("NouvelR-Regular"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("NouvelR-Regular"),
        },
        h1: {
            fontFamily: GetFontFamily("NouvelR-Bold"),
            fontSize: "2.5rem",
            lineHeight: "3rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("NouvelR-Bold"),
            fontSize: "2rem",
            lineHeight: "2.5rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.75rem",
                lineHeight: "2.25rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("NouvelR-Bold"),
            fontSize: "1.5rem",
            lineHeight: "2rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("NouvelR-Bold"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.125rem",
                lineHeight: "1.625rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("NouvelR-Regular"),
            color: colorCard?.subhead,
            fontSize: "1.25rem",
            lineHeight: "1.625rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("NouvelR-Bold"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("NouvelR-Regular"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.headlines,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.headlines,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            color: colorCard?.headlines,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
            },
            colorTextSecondary: {
                color: colorCard.headlines,
            },
        },
        MuiLink: {
            underlineHover: {
                color: colorCard?.headlines,
                "a&": {
                    fontFamily: GetFontFamily("NouvelR-Regular"),
                    fontWeight: 700,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                        textDecoration: "none",
                    },
                    "&:active": {
                        color: colorCard?.primaryHover,
                    },
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: colorCard?.headlines,
                "&.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "&.Mui-error": {
                    color: colorCard?.error,
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                border: "none",
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.headlines,
            },
            formControl: {
                transform: "none",
                color: colorCard.headlines,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: colorCard.cardBackground,
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.cardBackground,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard.inputOutline,
            },
            daySelected: {
                color: colorCard?.headlines,
            },
            current: {
                color: colorCard?.headlines,
                borderColor: colorCard?.headlines,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.headlines,
            },
            iconButton: {
                backgroundColor: colorCard?.tableOutline,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.tableOutline,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: colorCard?.tableOutline,
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: colorCard?.headlines,
            },
            toolbarBtnSelected: {
                color: colorCard?.headlines,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.headlines,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
                minWidth: "196px",
            },
            outlined: {
                fontFamily: GetFontFamily("NouvelR-Regular"),
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
                "&:hover": {
                    borderColor: colorCard?.secondaryHover,
                },
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                color: colorCard?.headlines,
                fontWeight: 700,
                "&:hover": {
                    backgroundColor: colorCard?.secondaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.secondaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.secondaryHover,
                    },
                    "&:active": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.headlines,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(renaultforetag);
