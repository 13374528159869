import { Config } from "../../Config";
export const FetchSearchResults = (searchQuery, siteId, resultCount, brand) => {
    const result = fetch(Config.epiBaseUrl + "Search/getSearchResults", {
        method: "post",
        body: JSON.stringify({ "query": searchQuery, "siteId": siteId, "resultCount": resultCount, "brand": brand }),
        headers: new Headers({
            Accept: "application/json",
            "content-type": "application/json",
            Referer: Config.epiBaseUrl,
        })
    }).then(response => {
        return response.json();
    }).then(result => {
        return result;
    });
    return result;
};
export default FetchSearchResults;
export const FetchAutocompleteSuggestions = async (searchQuery) => {
    return await fetch(`${Config.epiBaseUrl}Search/getAutocompleteSuggestions`, {
        method: "POST", headers: new Headers({
            Accept: "application/json",
            "content-type": "application/json",
            Referer: Config.epiBaseUrl,
        }),
        body: JSON.stringify({ "query": searchQuery })
    });
};
