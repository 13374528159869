import { useEffect } from "react";
import { scrollToHashElement } from "../helpers/hashFragmentHelper";
import { usePageContext } from "../Components/Context/PageContextProvider";
const useHashFragment = (imagesLoaded) => {
    const { pathname, hash } = window.location;
    const { selectedPage } = usePageContext();
    useEffect(() => {
        if (hash && selectedPage) {
            scrollToHashElement();
        }
    }, [pathname, hash, imagesLoaded, selectedPage]);
};
export default useHashFragment;
