//this runs after initial page after SSR fired
//to add split test data into session storage after redirect already happened
export function runSplitTestAfterSSRLoad(prefilledDataSSR) {
    //need to add that info to session storage if it is not already added there          
    let existingSessionStorage = sessionStorage.getItem("splitTesting");
    if (existingSessionStorage) {
        //need to parse existing data from session storage in case multiple pages
        //have split testing active
        let existingSplitConfiguration = JSON.parse(existingSessionStorage);
        existingSplitConfiguration.forEach((element) => {
            if (element.RedirectFrom
                == prefilledDataSSR.splitTestData.RedirectFrom) {
                //if already there is data about current split test in the session Storage
                return;
            }
        });
        //if we are here then split test data exists but not with current page configuration
        existingSplitConfiguration.push(JSON.parse(prefilledDataSSR.splitTestData));
        sessionStorage.setItem("splitTesting", JSON.stringify(existingSplitConfiguration));
    }
    else {
        sessionStorage.setItem("splitTesting", JSON.stringify(new Array(JSON.parse(prefilledDataSSR.splitTestData))));
    }
}
//run split test based on session storage data
//for cases when page is loaded when customer goes to another page in browsers
export function runSplitTest(pageData, pathName) {
    let calculatedWeight = 100;
    if (sessionStorage.getItem("splitTesting")) {
        //if split testing configuration already in the session
        //then just use that info and don't calculate the weight once more
        let splitTestData = JSON.parse(sessionStorage.getItem("splitTesting"));
        splitTestData.forEach((element) => {
            if (location.origin + pathName == element.RedirectFrom) {
                if (element.customerWeight < pageData.splitTestConfiguration.splitTestWeight.value) {
                    console.log('Initiating full split test redirect');
                    location.href = element.RedirectTo + location.search;
                    return true;
                }
            }
        });
    }
    //since max % of customer weight is 100 - calculate a random number between 0 and 100
    calculatedWeight = Math.round(Math.random() * 100);
    let splitTestInformation = {
        "RedirectFrom": window.location.href,
        "RedirectTo": pageData.splitTestConfiguration.testRedirectToPage.value.url,
        "CurrentScore": calculatedWeight,
        "TargetScore": pageData.splitTestConfiguration.splitTestWeight.value
    };
    let splitTestData = JSON.parse(sessionStorage.getItem("splitTesting"));
    if (!splitTestData) {
        splitTestData = [];
    }
    splitTestData.push(splitTestInformation);
    sessionStorage.setItem("splitTesting", JSON.stringify(splitTestData));
    if (calculatedWeight < pageData.splitTestConfiguration.splitTestWeight.value) {
        //if current calculated weight is smaller then wight from page configuration
        //then this customer suppose to participate in the split test - and be redirected         
        window.location.href = pageData.splitTestConfiguration.testRedirectToPage.value.url + location.search;
        return true;
    }
    return false;
}
//run split test based on session storage
//if data already exists there - do the redirect right away
export function runSplitTestFromSession(pathname) {
    if (sessionStorage.getItem("splitTesting")) {
        let splitTestData = JSON.parse(sessionStorage.getItem("splitTesting"));
        splitTestData.forEach((element) => {
            if (location.origin + pathname == element.RedirectFrom) {
                if (element.CurrentScore < element.TargetScore) {
                    console.log('Initiating full split test redirect');
                    location.href = element.RedirectTo + location.search;
                }
            }
        });
    }
}
export default runSplitTest;
