import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import * as Helpers from "./common/Helpers";
import { placemnet } from "./common/Theme";
// create default theme to get breakpoints
const theme = createTheme();
const variables = {
    common: {
        borderRadius: 3,
    },
    colors: {
        primary: {
            main: "#547B83",
            dark: "#465A59",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#AB6E44",
            dark: "#875735",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#C82E2D",
        },
        text: {
            primary: "#333333",
            secondary: "#4c4c4c",
            hint: "#5C7775",
        },
        background: "#FFFFFF",
        elementBackground: "#F6F8F9",
        border: "#DBDBDB",
        hover: "#FAFAFA",
        placeholder: "#999999",
        transparent: "#00000000",
        divider: "#EDF1F2",
        teaserBackground: "#BACACD",
        entryBackground: "#DDE4E6",
        icons: {
            main: "#87A2A8",
        },
        gdprText: "#757575",
        tooltipBackgroundColor: "#333333",
    },
};
const defaultPathParams = {
    stroke: "currentColor",
    strokeWidth: "1.5",
    fill: "none",
};
function getFontFamily(mainFont) {
    return [
        mainFont ?? "",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
    ].join(",");
}
const props = Helpers.PrepareProperties(variables);
// More properties here: https://material-ui.com/customization/default-theme/
export const polestarforetagfi = {
    themeName: "PolestarForetagFI",
    brand: BrandsEnum.PolestarForetagFI,
    compositionBoxWidth: 816,
    containerMaxWidth: 384,
    pageMaxWidth: 1336,
    centred: "0 auto",
    border: "1px solid ",
    borderRadius: variables.common.borderRadius,
    shape: {
        borderRadius: variables.common.borderRadius,
    },
    header: {
        uspBannerBackground: variables.colors.text.primary,
        logoPlacement: placemnet.LEFT,
        dividerColor: variables.colors.elementBackground,
        dividerHeight: 1,
        navigationLinkColor: variables.colors.text.primary,
    },
    breadcrumbs: {
        activeColor: variables.colors.primary.main,
        color: variables.colors.gdprText,
        iconColor: variables.colors.primary.main,
        activeFontFamily: getFontFamily("Volvo-Novum-Medium"),
    },
    contentBox: {
        backgroundColor: variables.colors.elementBackground,
        textColor: variables.colors.text.primary,
    },
    webshopEntry: {
        backgroundColor: variables.colors.entryBackground,
        gdprTextColor: variables.colors.gdprText,
    },
    USPContainerBlock: {
        backgroundColor: variables.colors.elementBackground,
        textColor: variables.colors.text.secondary,
        headerColor: variables.colors.primary.main,
    },
    mobileAppBlock: {
        backgroundColor: variables.colors.elementBackground,
    },
    footer: {
        backgroundColor: variables.colors.elementBackground,
        border: false,
        mobileFooterPlacement: placemnet.LEFT,
    },
    tooltip: {
        backgroundColor: variables.colors.tooltipBackgroundColor,
    },
    factAndIcon: {
        backgroundColor: variables.colors.elementBackground,
    },
    publishDate: {
        color: variables.colors.gdprText,
    },
    customStyling: {
        global: {
            textArea: {
                legalText: {
                    color: variables.colors.gdprText,
                },
            },
            secondaryButton: {
                "&:hover": {
                    backgroundColor: "rgba(84, 123, 131, 0.04)",
                },
            },
        },
    },
    icons: {
        expandIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
            React.createElement("g", { id: "Group_12431", "data-name": "Group 12431", transform: "translate(-730.956 -372.5)" },
                React.createElement("path", { id: "Path_10975", "data-name": "Path 10975", d: "M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z", transform: "translate(754.956 372.5) rotate(90)", fill: "#87a2a8", fillRule: "evenodd" }),
                React.createElement("g", { id: "Layer_1", transform: "translate(736.956 378.5)" },
                    React.createElement("path", { id: "Path_10974", "data-name": "Path 10974", d: "M12,5.85H6.15V0h-.3V5.85H0v.3H5.85V12h.3V6.15H12Z", fill: "#fff", stroke: "#fff", strokeWidth: "0.5" }))))),
        expandIconRotation: "45",
        helpIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20.5", height: "20.5", viewBox: "0 0 20.5 20.5" },
            React.createElement("g", { id: "Help", transform: "translate(-1.25 -1.25)" },
                React.createElement("path", { id: "Path_5110", "data-name": "Path 5110", d: "M11.5,21.5a10,10,0,1,1,10-10A10.012,10.012,0,0,1,11.5,21.5Zm0-19.459A9.459,9.459,0,1,0,20.959,11.5,9.5,9.5,0,0,0,11.5,2.041Z", fill: "#547b83", stroke: "#547b83", strokeWidth: "0.5" }),
                React.createElement("path", { id: "Path_5111", "data-name": "Path 5111", d: "M18.065,18.365h-.541v-.541c0-1.459.054-1.784,1.73-3.081,1.568-1.243,2.162-3.027,1.351-4.216a2.8,2.8,0,0,0-2.486-1.189,2.653,2.653,0,0,0-2.324,1.405l-.108.216-.486-.27.108-.216A3.256,3.256,0,0,1,18.065,8.8,3.592,3.592,0,0,1,21.038,10.2c.973,1.459.378,3.514-1.459,4.973-1.514,1.189-1.514,1.351-1.514,2.649Z", transform: "translate(-6.295 -3.351)", fill: "#547b83", stroke: "#547b83", strokeWidth: "0.5" }),
                React.createElement("rect", { id: "Rectangle_2805", "data-name": "Rectangle 2805", width: "0.541", height: "0.865", transform: "translate(11.23 16.635)", fill: "#547b83", stroke: "#547b83", strokeWidth: "0.5" })))),
        contactUsExpandIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
            React.createElement("g", { id: "Group_12433", "data-name": "Group 12433", transform: "translate(-713 -414.484)" },
                React.createElement("path", { id: "Path_10976", "data-name": "Path 10976", d: "M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z", transform: "translate(737 414.484) rotate(90)", fill: "#87a2a8", fillRule: "evenodd" }),
                React.createElement("g", { id: "Layer_1", transform: "translate(718.5 412.998)" },
                    React.createElement("path", { id: "Path_10970", "data-name": "Path 10970", d: "M12.072,10.5l-5.6,5.551L.928,10.5H.5l5.975,5.975L12.5,10.5Z", fill: "#fff", stroke: "#fff", strokeWidth: "0.5" })),
                React.createElement("g", { id: "Layer_1-2", "data-name": "Layer_1", transform: "translate(718.5 412.998)" },
                    React.createElement("path", { id: "Path_10970-2", "data-name": "Path 10970", d: "M12.072,10.5l-5.6,5.551L.928,10.5H.5l5.975,5.975L12.5,10.5Z", fill: "#fff", stroke: "#fff", strokeWidth: "0.5" }))))),
        checkmarkIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
            React.createElement("g", { id: "Group_12436", "data-name": "Group 12436", transform: "translate(-1060 -323.445)" },
                React.createElement("path", { id: "Path_4742", "data-name": "Path 4742", d: "M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z", transform: "translate(1084 323.445) rotate(90)", fill: "#dde4e6", fillRule: "evenodd" }),
                React.createElement("path", { id: "Checkmark", d: "M6.425,18.4,13.768,11l.232.23L6.425,18.866,2,14.4l.232-.23Z", transform: "translate(1064 320.513)", fill: "#333", stroke: "#070708", strokeWidth: "0.5", fillRule: "evenodd" })))),
        checkmarkIconCSS: encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_12436" data-name="Group 12436" transform="translate(-1060 -323.445)">
      <path id="Path_4742" data-name="Path 4742" d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z" transform="translate(1084 323.445) rotate(90)" fill="#dde4e6" fill-rule="evenodd"/>
      <path id="Checkmark" d="M6.425,18.4,13.768,11l.232.23L6.425,18.866,2,14.4l.232-.23Z" transform="translate(1064 320.513)" fill="#333" stroke="#070708" stroke-width="0.5" fill-rule="evenodd"/>
    </g>
  </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z" fill="#DDE4E6" stroke="#DDE4E6" stroke-width="0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.038 15.467L18.381 8.06699L18.613 8.29699L11.038 15.933L6.61304 11.467L6.84504 11.237L11.038 15.467Z" fill="#547B83" stroke="#547B83" stroke-width="0.75"/>
      </svg>`),
        closeIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16.707", height: "16.707", viewBox: "0 0 16.707 16.707" },
            React.createElement("path", { id: "Close", d: "M14,14.394,21.606,22,22,21.606,14.394,14,22,6.394,21.606,6,14,13.606,6.394,6,6,6.394,13.606,14,6,21.606,6.394,22Z", transform: "translate(-5.646 -5.646)", fill: "#547b83", stroke: "#547b83", strokeWidth: "0.5" }))),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: variables.colors.entryBackground }))),
        bulletIconCSS: encodeURIComponent(`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="1.6"
        viewBox="0 0 14 1.6"
      >
        <line
          id="Line_861"
          data-name="Line 861"
          x2="14"
          transform="translate(0 0.8)"
          fill="none"
          stroke="#001e50"
          stroke-width="1.6"
        />
      </svg>
    `),
    },
    link: {
        showArrow: true,
        showUnderline: false,
    },
    accordionBlock: {
        expandedBackgroundColor: variables.colors.elementBackground,
        comparationTableBackground: variables.colors.elementBackground,
        backgroundColor: variables.colors.divider,
        borderColor: variables.colors.divider,
    },
    tetriaryLink: {
        underlined: false,
    },
    textBlock: {
        tableBackground: variables.colors.divider,
        tableBorderColor: variables.colors.border,
        linkHoverColor: variables.colors.text.primary,
    },
    contactUs: {
        backgroundColor: variables.colors.divider,
        activeBackgroundColor: variables.colors.elementBackground,
    },
    topCarUSP: {
        gradientColor: variables.colors.divider,
    },
    palette: {
        primary: variables.colors.primary,
        secondary: variables.colors.secondary,
        error: variables.colors.error,
        text: variables.colors.text,
        backgroundSecondary: {
            default: variables.colors.background,
            paper: variables.colors.divider,
        },
        background: {
            default: variables.colors.background,
            paper: variables.colors.divider,
        },
        divider: variables.colors.border,
        grey: {
            400: variables.colors.border,
        },
        icons: {
            main: variables.colors.icons.main,
        },
    },
    typography: {
        fontFamily: getFontFamily("Volvo-Novum-Regular"),
        color: variables.colors.text.primary,
        root: {
            fontFamily: getFontFamily("Volvo-Novum-Regular"),
        },
        h1: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h5: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "1.125rem",
            color: variables.colors.primary.main,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h6: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            color: variables.colors.secondary.main,
            "word-break": "break-word",
            fontSize: "1rem",
            lineHeight: "1.75rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: getFontFamily("Volvo-Novum-Medium"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.primary,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.primary,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: variables.colors.primary.main,
        },
    },
    teaser: {
        root: {
            backgroundColor: variables.colors.elementBackground,
            border: false,
        },
        container: {
            backgroundColor: variables.colors.teaserBackground,
        },
    },
    navigation: {
        backgroundColor: variables.colors.elementBackground,
        divider: variables.colors.divider,
        iconColor: variables.colors.text.secondary,
        megaBackgroundColor: variables.colors.elementBackground,
        mobileCollapseBackgroundColor: variables.colors.elementBackground,
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: getFontFamily("Volvo-Novum-Regular"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: variables.colors.primary.main,
                    fontWeight: 700,
                    textDecoration: "none",
                    "&:hover": {
                        color: variables.colors.text.primary,
                    },
                    "&:active": {
                        color: variables.colors.text.hint,
                    },
                },
            },
        },
        MuiMobileStepper: {
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiTooltip: {
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: variables.colors.transparent,
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 3,
                "text-transform": "none",
            },
            outlined: {
                color: variables.colors.primary.main,
                borderColor: variables.colors.primary.main,
            },
            contained: {
                boxShadow: props.buttons.boxShadow,
                "&:hover": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&:active": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&$focusVisible": {
                    boxShadow: props.buttons.boxShadow,
                },
                "a&": {
                    "&:hover": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                    "&:active": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                    color: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.main,
                    textDecoration: "none",
                },
            },
        },
        MuiInputLabel: {
            formControl: {
                transform: "none",
                color: variables.colors.primary.main,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
    },
};
export default createTheme(polestarforetagfi);
