import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#141414",
    secondary: "#003E51",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F8F8F8",
    accordionClosed: "#F5F5F5",
    tableOutline: "#D6D6D6",
    dividerLine: "#D6D6D6",
    secondaryHover: "#D6D6D6",
    background3: "#D6D6D6",
    wcagOutline: "#858585",
    inputOutline: "#858585",
    placeholderText: "#858585",
    wcag: "#5C5C5C",
    gdprText: "#5C5C5C",
    helperText: "#5C5C5C",
    primaryHover: "#5C5C5C",
    tetriaryHover: "#5C5C5C",
    subhead: "#333333",
    bodyText: "#333333",
    headlines: "#1A1A1A",
    label: "#141414",
    inputText: "#141414",
    success: "#17582E",
    successBackground: "#DAE4DD",
    info: "#1E369B",
    infoBackground: "#DBDFEF",
    warning: "#FFE668",
    warningBackground: "#FFFBE7",
    error: "#FF0012",
    errorBackground: "#FFD6D9",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const mg = {
    themeName: "mg",
    colorCard: colorCard,
    brand: BrandsEnum.MG,
    shape: {
        borderRadius: 24,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: "underline",
                "&:hover": {
                    color: colorCard.primary,
                },
            },
            tetriaryLink: {
                textDecoration: "underline",
                "&:hover": {
                    color: colorCard.primary,
                },
            },
            textArea: {
                a: {
                    color: colorCard.subhead,
                    textDecoration: "underline",
                    "&:active": {
                        color: colorCard.primaryHover,
                    },
                },
                h1: {
                    color: colorCard.subhead,
                },
                h2: {
                    color: colorCard.subhead,
                },
                h3: {
                    color: colorCard.subhead,
                },
                h4: {
                    color: colorCard.subhead,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            primaryButton: {
                color: colorCard.background,
                backgroundColor: colorCard.primary,
                borderColor: colorCard.primary,
                borderRadius: 24,
                fontFamily: GetFontFamily("Favorit Regular"),
                "&:hover, &:focus": {
                    border: `2px solid ${colorCard.primary}`,
                    backgroundColor: colorCard.background,
                    color: colorCard.primary,
                },
            },
            secondaryButton: {
                borderColor: colorCard.subhead,
                borderRadius: 24,
                fontFamily: GetFontFamily("Favorit Regular"),
                "&:hover, &:focus": {
                    backgroundColor: colorCard.background,
                    color: colorCard.primary,
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.subhead,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Favorit Regular"),
            },
            icon: {
                display: "block",
                fill: colorCard.label,
            },
            "&:hover": {
                color: colorCard.primary,
            },
        },
        MegaMenu: {
            linkIcon: {
                fill: colorCard.label,
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
            "&:hover": {
                color: colorCard.primary,
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        ArticleTeaser: {
            root: {
                borderRadius: 12,
                border: `1px solid ${colorCard.tableOutline}`,
            },
        },
        ContactUsAreaTabHead: {
            tabsWrapper: {
                [theme.breakpoints.up("sm")]: {
                    borderRadius: 12,
                },
            },
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
        },
        TeaserFullPage: {
            image: {
                borderRadius: 12,
            },
            root: {
                border: "none",
            },
        },
        TextBlock: {
            image: {
                borderRadius: 12,
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderRadius: "12px",
                border: "none",
            },
            accordionHeader: {
                borderRadius: "12px",
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
                borderRadius: "12px",
            },
        },
        LargeTeaserWithUsp: {
            image: {
                borderRadius: 12,
            },
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                backgroundColor: colorCard.primary,
            },
            heroWithHeadlineBody: {
                primaryButton: {
                    color: colorCard.primary,
                    fontFamily: GetFontFamily("Favorit Regular"),
                    backgroundColor: colorCard.background,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                        backgroundColor: colorCard.primary,
                        color: colorCard.background,
                    },
                },
                containedPrimaryButton: {
                    color: colorCard.primary,
                    fontFamily: GetFontFamily("Favorit Regular"),
                    backgroundColor: colorCard.background,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                        backgroundColor: colorCard.primary,
                        color: colorCard.background,
                    },
                },
                secondaryButton: {
                    color: colorCard?.background,
                    fontFamily: GetFontFamily("Favorit Regular"),
                    borderColor: colorCard?.background,
                    "&:hover": {
                        "&:hover": {
                            backgroundColor: colorCard.background,
                            color: colorCard.primary,
                        },
                    },
                },
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.primary,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.label,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.primary,
            },
            root: {
                borderRadius: 12,
            },
        },
        CustomizedTextBox: {
            textBoxInput: {
                backgroundColor: colorCard.accordionClosed,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        Navigation: {
            menuButtonText: {
                fontFamily: GetFontFamily("Favorit Regular"),
            },
        },
        Item: {
            link: {
                fontFamily: GetFontFamily("Favorit Regular"),
            },
            active: {
                fontFamily: GetFontFamily("Favorit Regular"),
            },
        },
        NavigationItems: {
            activeItem: {
                color: colorCard.primary,
            },
            item: {
                "& div": {
                    fontFamily: GetFontFamily("Favorit Regular"),
                },
                fontFamily: GetFontFamily("Favorit Regular"),
            },
            thirdLevel: {
                fontFamily: GetFontFamily("Favorit Regular"),
            },
            secondLevel: {
                fontFamily: GetFontFamily("Favorit Regular"),
            },
        },
        FactAndIcon: {
            root: {
                borderRadius: 12,
                border: `1px solid ${colorCard.tableOutline}`,
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.dividerLine}`,
                borderBottom: `1px solid ${colorCard?.dividerLine}`,
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
            },
            footerBottomWrapper: {
                "& .MuiLink-root": {
                    fontFamily: GetFontFamily("Favorit Regular"),
                    "&:hover": {
                        color: colorCard.primary,
                    },
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
            },
        },
        SubmitButtonElementBlock: {
            submitButton: {
                backgroundColor: colorCard.headlines,
            },
        },
    },
    teaser: {
        root: {
            backgroundColor: colorCard.cardBackground,
            border: true,
            borderColor: colorCard.tableOutline,
        },
        container: {
            backgroundColor: colorCard.background3,
        },
    },
    webshopEntry: {
        backgroundColor: colorCard.background3,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
    },
    header: {
        uspBannerBackground: colorCard.primary,
        backgroundColor: colorCard.background,
        navigationLinkColor: colorCard.label,
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background2,
        backgroundColor: colorCard.background2,
        divider: colorCard.dividerLine,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
        darkModeTextColor: colorCard.background2,
    },
    layout: {
        backgroundColor: colorCard.background,
    },
    accordionBlock: {
        expandedBackgroundColor: colorCard.cardBackground,
        backgroundColor: colorCard.accordionClosed,
    },
    contactUs: {
        backgroundColor: colorCard.accordionClosed,
        activeBackgroundColor: colorCard.tableOutline,
    },
    factAndIcon: {
        backgroundColor: colorCard.cardBackground,
    },
    breadcrumbs: {
        activeColor: colorCard.primary,
        color: colorCard.primary,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
        headerColor: colorCard.background2,
    },
    icons: {
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#141414" }))),
        expandIconRotation: "45",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#141414" }))),
        expandIconRotationNavigation: "45",
        comparisonTableExpand: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 15.0282L19.0734 7.9548C19.1789 7.84934 19.307 7.79661 19.4576 7.79661C19.6083 7.79661 19.7363 7.84934 19.8418 7.9548C19.9473 8.06026 20 8.18832 20 8.33898C20 8.48964 19.9473 8.6177 19.8418 8.72316L12.9492 15.6158C12.8136 15.7514 12.6629 15.8493 12.4972 15.9096C12.3315 15.9699 12.1657 16 12 16C11.8343 16 11.6686 15.9699 11.5028 15.9096C11.3371 15.8493 11.1864 15.7514 11.0508 15.6158L4.15819 8.72316C4.05273 8.6177 4 8.48964 4 8.33898C4 8.18832 4.05273 8.06026 4.15819 7.9548C4.26365 7.84934 4.39171 7.79661 4.54237 7.79661C4.69303 7.79661 4.82109 7.84934 4.92655 7.9548L12 15.0282Z", fill: "#141414" }))),
        comparisonTableExpandRotation: "180",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#141414" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#141414" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "#05141F" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#141414"/>
      </svg>
      `),
        heroCheckmarkIconCSS: encodeURIComponent(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#FFFFFF" stroke-miterlimit="10"/>
    <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#FFFFFF" stroke-miterlimit="10"/>
    </svg>
    `),
        bulletIconCSS: encodeURIComponent(`
    <svg
     width="24"
     height="24"
     viewBox="0 0 24 24"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z"
    stroke="#95572B"
     />
      <path
    d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z"
    fill="#95572B"
    />
    </svg>
    `),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "currentColor" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.487 17H12.5304V10.8869H11.487V17ZM11.997 9.33042C12.1918 9.33042 12.3511 9.26946 12.475 9.14754C12.5989 9.02561 12.6609 8.86727 12.6609 8.67253C12.6609 8.4778 12.5999 8.31847 12.478 8.19456C12.356 8.07064 12.1977 8.00869 12.003 8.00869C11.8082 8.00869 11.6489 8.06966 11.525 8.19159C11.4011 8.31351 11.3391 8.47184 11.3391 8.66657C11.3391 8.86131 11.4001 9.02063 11.522 9.14455C11.644 9.26846 11.8023 9.33042 11.997 9.33042ZM12.0052 22C10.6223 22 9.3217 21.7373 8.10328 21.2119C6.88486 20.6865 5.825 19.9735 4.9237 19.0729C4.02241 18.1722 3.3098 17.1144 2.78588 15.8995C2.26196 14.6846 2 13.3865 2 12.0052C2 10.6223 2.26269 9.3217 2.78808 8.10328C3.31347 6.88486 4.02649 5.825 4.92715 4.9237C5.82782 4.0224 6.88561 3.3098 8.10051 2.78588C9.3154 2.26196 10.6135 2 11.9948 2C13.3777 2 14.6783 2.26269 15.8967 2.78808C17.1151 3.31347 18.175 4.02649 19.0763 4.92715C19.9776 5.82782 20.6902 6.88561 21.2141 8.10051C21.738 9.3154 22 10.6135 22 11.9948C22 13.3777 21.7373 14.6783 21.2119 15.8967C20.6865 17.1151 19.9735 18.175 19.0729 19.0763C18.1722 19.9776 17.1144 20.6902 15.8995 21.2141C14.6846 21.738 13.3865 22 12.0052 22ZM11.9993 20.9565C14.4918 20.9565 16.6078 20.087 18.3473 18.348C20.0868 16.609 20.9565 14.4932 20.9565 12.0007C20.9565 9.50821 20.087 7.39221 18.348 5.65272C16.609 3.91322 14.4932 3.04348 12.0007 3.04348C9.50821 3.04348 7.39221 3.91298 5.65272 5.65198C3.91322 7.39101 3.04348 9.50678 3.04348 11.9993C3.04348 14.4918 3.91298 16.6078 5.65198 18.3473C7.39101 20.0868 9.50678 20.9565 11.9993 20.9565Z", fill: "#141414" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Favorit Light"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Favorit Light"),
        },
        h1: {
            fontFamily: GetFontFamily("Favorit Regular"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Favorit Regular"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Favorit Regular"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Favorit Regular"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Favorit Light"),
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Favorit Regular"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Favorit Light"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Favorit Light"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.headlines,
                    "&:hover": {
                        color: colorCard?.primary,
                    },
                    "&:active": {
                        color: colorCard?.secondaryHover,
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "6px 6px 0 0",
                border: "none",
                backgroundColor: colorCard.accordionClosed,
            },
            input: {
                borderRadius: "6px 6px 0 0",
                backgroundColor: colorCard.accordionClosed,
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 24,
                "text-transform": "none",
            },
            label: {
                "text-align": "center",
            },
            outlined: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    "&:hover": {
                        backgroundColor: colorCard.primary,
                        color: colorCard.background,
                    },
                },
            },
            outlinedPrimary: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    borderWidth: 2,
                    borderColor: colorCard?.label,
                },
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    border: `2px solid ${colorCard.primary}`,
                    backgroundColor: colorCard.background,
                    color: colorCard.primary,
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        border: `2px solid ${colorCard.primary}`,
                        backgroundColor: colorCard.background,
                        color: colorCard.primary,
                    },
                    "&:active": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.cardBackground,
                    backgroundColor: colorCard?.label,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(mg);
