import { useEffect } from "react";
const OneTrustCookiesConsentScriptLoader = ({ language, domainId }) => {
    useEffect(() => {
        if (!domainId || !language)
            return;
        const otSDKStubScript = document?.createElement("script");
        otSDKStubScript.async = true;
        otSDKStubScript.type = "text/javascript";
        otSDKStubScript.charset = "UTF-8";
        otSDKStubScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        otSDKStubScript.setAttribute("data-language", language);
        otSDKStubScript.setAttribute("data-domain-script", domainId);
        document?.head.appendChild(otSDKStubScript);
        const optanonWrapperScript = document?.createElement("script");
        optanonWrapperScript.type = "text/javascript";
        optanonWrapperScript.innerHTML = `function OptanonWrapper() { };`;
        document?.head.appendChild(optanonWrapperScript);
        return () => {
            document?.head.removeChild(otSDKStubScript);
            document?.head.removeChild(optanonWrapperScript);
        };
    }, [language, domainId]);
    return null;
};
export default OneTrustCookiesConsentScriptLoader;
