import React from "react";
import { makeStyles, Box } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    text: props => ({
        color: theme.header?.uspBannerTextColor || theme.colorCard?.background || theme.palette?.primary?.contrastText,
        backgroundImage: `url(${props.imageUrl})`,
        backgroundSize: "40px 40px",
        backgroundRepeat: "no-repeat",
        backgrounsPositionX: "left",
        backgroundPositionY: "center",
        paddingLeft: "56px",
        marginLeft: "8px",
        marginRight: "8px",
    }),
    root: {
        maxHeight: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.header?.uspBannerBackground || theme.colorCard?.primary,
        ...theme.customStyling?.USPBanner?.root,
    },
}));
export const USPBanner = (props) => {
    const classes = useStyles(props);
    function RawContentMarkup(rawHtml) {
        return { __html: rawHtml };
    }
    return (React.createElement(Box, { className: classes.root },
        React.createElement("div", { dangerouslySetInnerHTML: RawContentMarkup(props.text), className: classes.text })));
};
export default USPBanner;
