import React, { createContext, useRef, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useIContentRepository, useEpiserver, useContentDeliveryAPI, } from "@episerver/spa-core";
import { setLanguage } from "@episerver/spa-core/State/Tools";
import { runTrackingHook } from "../../Components/Service/StatisticsScript";
import { DefaultServices } from "@episerver/spa-core/Core/IServiceContainer";
import Config from "../../Config";
import { runSplitTest, runSplitTestAfterSSRLoad, runSplitTestFromSession } from "../Service/SplitTestRunner";
import usePageReferrer from "../../hooks/usePageReferrer";
export const PageContext = createContext(undefined);
export const PageProvider = ({ children }) => {
    const epiState = useEpiserver();
    const useContent = useContentDeliveryAPI();
    const contentDelivery = useIContentRepository();
    const { pathname } = useLocation();
    const pageReferrer = usePageReferrer();
    const [loadingData, setLoadingData] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedRootMenuItem, setSelectedRootMenuItem] = useState(null);
    const [hideMOBanner, setHideMOBanner] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [startPageId, setStartPageId] = useState(null);
    const [contentPageData, setContentPageData] = useState(null);
    const [alertBlocksInContentPage, setAlertBlocksInContentPage] = useState(null);
    const [startPageData, setStartPageData] = useState(typeof prefilledDataSSR !== "undefined"
        ? JSON.parse(prefilledDataSSR.startPageData)
        : undefined);
    if (startPageData) {
        setLanguage(startPageData.language.name, epiState.getStore());
    }
    const [brand, setBrand] = useState(startPageData?.brand?.value
        ? startPageData.brand.value.toLowerCase()
        : "none");
    const [isUSPBanner, setIsUSPBanner] = useState(startPageData?.useMOBanner.value ?? false);
    const firstUpdate = useRef(true);
    const isServerSideRendering = epiState.isServerSideRendering();
    const alertBlocksStorageKey = "alertBlocksInContentPage";
    const getPage = async () => {
        //following code will run only on first page load after page reload
        if (firstUpdate.current) {
            //this provides a Icontent of the current page when it is first loaded
            //when using SSR - it will have page data preloaded from server (inside INITIAL DATA object)
            //this step saves one request and makes first page load a bit faster
            const service = epiState.serviceContainer.getService(DefaultServices.ServerContext);
            if (service?.IContent) {
                //SSR case
                window.document.documentElement.lang =
                    service.IContent.masterLanguage?.name;
                setHideMOBanner(service.IContent.contentType.includes("BraviWebShopPage"));
                setLanguage(service.IContent.masterLanguage.name, epiState.getStore());
                runTrackingHook(service.IContent, startPageData, pageReferrer);
                firstUpdate.current = false;
                //this means current page is result of split test redirect
                //and we are coming to page redirects TO.
                if (prefilledDataSSR.splitTestData) {
                    console.log('Split test data located', prefilledDataSSR.splitTestData);
                    runSplitTestAfterSSRLoad(prefilledDataSSR);
                }
                return;
            }
            else {
                //this case fires when run on local 9000 port, so using default language from config file
                setLanguage(Config.defaultLanguage, epiState.getStore());
            }
            //firstUpdate.current = false;
        }
        setLoadingData(true);
        runSplitTestFromSession(pathname);
        let result = null;
        let contentType = null;
        try {
            result = await contentDelivery.getByRoute(pathname);
            //here need to check if we don't have session data about split test for that page
            //which we probalby doesn't as it is checked above but just in case
            //and get split test info from that RESULT and then calculate the score for the user
            //then add info to the session storage
            let parsedData = result;
            if (parsedData.splitTestConfiguration && parsedData.splitTestConfiguration.splitTestWeight.value != null
                && parsedData.splitTestConfiguration.testRedirectToPage.value != null) {
                //if test fires redirect - just use return here to prevert future page loading
                if (runSplitTest(parsedData, pathname)) {
                    return;
                }
            }
            //end of split test functionality
            runTrackingHook(result, startPageData, pageReferrer);
            contentType = result.contentType;
        }
        catch (ex) {
            console.log(ex); // TODO: log the error in application insight
        }
        setHideMOBanner(contentType.includes("BraviWebShopPage"));
        if (contentType.includes("BraviContentPage") ||
            contentType.includes("BraviWebShopPage") ||
            contentType.includes("BraviSearchResultsPage")) {
            const parsedResult = result;
            if (parsedResult.topStartPageId.value !== startPageId) {
                getTopPage(parsedResult.topStartPageId.value);
            }
        }
        else if (contentType.includes("BraviStartPage")) {
            // reset content-page-related info
            try {
                sessionStorage.removeItem(alertBlocksStorageKey);
            }
            catch (e) {
                //Ignore on purpose
            }
            setAlertBlocksInContentPage(null);
            setContentPageData(null);
            updateStartPage(result);
        }
        // This part finds alert blocks in the content page and put them into alertBlocksInContentPage
        if (contentType.includes("BraviContentPage")) {
            const parsedResult = result;
            setContentPageData(parsedResult);
            setAlertBlocksInContentPage(null); //reset old data, will be filled in below.
            const alertBlocksInContentPage = [];
            if (parsedResult.alertArea?.value) {
                alertBlocksInContentPage.push(parsedResult.alertArea);
            }
            let subpage = parsedResult;
            const pathParts = pathname.split("/").filter((segment) => segment !== "");
            // excliding the last child page as it is the same as res/parsedResult
            const pageIterations = pathParts.length - 1;
            if (pageIterations > 0) {
                for (let i = 0; i < pageIterations; i++) {
                    try {
                        const page = await contentDelivery.getByRoute("/" + subpage.parentLink.url);
                        if (page.alertArea?.value) {
                            alertBlocksInContentPage.push(page.alertArea);
                        }
                        subpage = page;
                    }
                    catch (error) {
                        console.log(error); // TODO: log the error in application insight
                    }
                }
            }
            alertBlocksInContentPage.reverse();
            setAlertBlocksInContentPage(alertBlocksInContentPage);
            if (alertBlocksInContentPage.length > 0) {
                try {
                    sessionStorage.setItem(alertBlocksStorageKey, JSON.stringify(alertBlocksInContentPage));
                }
                catch (e) {
                    //Ignore on purpose
                }
            }
        }
        setLoadingData(false);
    };
    useEffect(() => {
        getPage();
    }, [pathname]);
    const getTopPage = async (startPageId) => {
        try {
            const page = (await useContent.getContent(startPageId));
            updateStartPage(page);
        }
        catch (error) {
            console.log(error); // TODO: log the error in application insight
        }
    };
    const updateStartPage = (startPageData) => {
        window.document.documentElement.lang = startPageData?.language?.name;
        setStartPageId(startPageData.topStartPageId.value);
        setStartPageData(startPageData);
        setIsUSPBanner(startPageData.useMOBanner.value);
        if (startPageData.brand.value)
            setBrand(startPageData.brand.value.toLowerCase());
    };
    // When refreshing a page, alert block data is lost so saving it in browser storage.
    let cached = null;
    try {
        cached = sessionStorage.getItem(alertBlocksStorageKey);
    }
    catch (e) {
        //Ignore on purpose
    }
    if (startPageData &&
        cached &&
        !contentPageData &&
        !alertBlocksInContentPage) {
        setAlertBlocksInContentPage(JSON.parse(cached));
    }
    return (React.createElement(PageContext.Provider, { value: {
            brand,
            loadingData,
            startPageData,
            hideFooter,
            hideMOBanner,
            isUSPBanner,
            selectedPage,
            selectedRootMenuItem,
            alertBlocksInContentPage,
            setHideFooter,
            setSelectedPage,
            setSelectedRootMenuItem,
            isServerSideRendering,
        } }, children));
};
export default PageProvider;
export const usePageContext = () => {
    const context = React.useContext(PageContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a PageContextProvider");
    }
    return context;
};
