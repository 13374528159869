import React from "react";
import { makeStyles, Link, SvgIcon, useTheme } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    linkWithIcon: {
        display: "flex",
        alignItems: "center",
        textDecoration: theme.link?.showUnderline ? "underline" : "none",
        ...theme.customStyling?.LinkWithArrow?.linkWithIcon,
    },
    icon: {
        marginLeft: 8,
        fontSize: "0.75rem",
        display: theme.link?.showArrow ? "block" : "none",
        ...theme.customStyling?.LinkWithArrow?.icon,
    },
}));
export const LinkWithArrow = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    return (React.createElement(Link, { href: props.link.url, className: clsx(classes.linkWithIcon, props.linkClass), target: props.link.target },
        React.createElement("div", null, props.link.text),
        theme.icons?.linkArrowIcon ? React.createElement(SvgIcon, { className: clsx(classes.icon, props.iconClass) }, theme.icons?.linkArrowIcon) :
            React.createElement(ArrowForwardIosIcon, { className: clsx(classes.icon, props.iconClass) })));
};
export default LinkWithArrow;
