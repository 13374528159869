import { useEffect, useState, } from 'react';
import { useLocation } from 'react-router-dom';
const usePageReferrer = () => {
    const location = useLocation();
    const [previousPathname, setPreviousPathname] = useState('undefined');
    useEffect(() => {
        setPreviousPathname(location.pathname);
    }, [location]);
    return previousPathname !== "undefined" ? window.location.origin + previousPathname : previousPathname;
};
export default usePageReferrer;
