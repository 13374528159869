import React, { useState } from 'react';
export const SearchResultsContext = React.createContext(undefined);
// Provider component
export const SearchResultsProvider = ({ children }) => {
    // State to hold the search results
    const [query, setQuery] = useState("");
    const [results, setResults] = useState(undefined);
    //Function to update search results
    const updateSearchResults = (results) => {
        setResults(results);
    };
    //Function to update search query
    const updateSearchQuery = (searchQuery) => {
        const oldQuery = query;
        if (oldQuery !== searchQuery) {
            {
                // Clear the results if the query has changed
                setResults(undefined);
                setQuery(searchQuery);
            }
        }
    };
    return (React.createElement(SearchResultsContext.Provider, { value: { query, results, updateSearchQuery, updateSearchResults } }, children));
};
export default SearchResultsProvider;
// Custom hook to use the context
export const useSearchResultsContext = () => {
    const context = React.useContext(SearchResultsContext);
    if (context === undefined) {
        throw new Error("SearchResultsContext is undefined.");
    }
    return context;
};
