import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#000000",
    secondary: "#003E51",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F5F5F5",
    accordionClosed: "#F5F5F5",
    tableOutline: "#B5B4BA",
    dividerLine: "#DAD9DE",
    secondaryHover: "#DAD9DE",
    background3: "#DAD9DE",
    wcagOutline: "#737278",
    inputOutline: "#737278",
    placeholderText: "#737278",
    wcag: "#535457",
    gdprText: "#535457",
    helperText: "#535457",
    primaryHover: "#535457",
    tetriaryHover: "#535457",
    subhead: "#403F45",
    bodyText: "#403F45",
    headlines: "#000000",
    label: "#000000",
    inputText: "#141414",
    success: "#09D087",
    successBackground: "#DAE4DD",
    info: "#178BFF",
    infoBackground: "#DAECFF",
    warning: "#F7CB47",
    warningBackground: "#FFFBE7",
    error: "#FC4040",
    errorBackground: "#FFD6D9",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const porsche = {
    themeName: "porsche",
    colorCard: colorCard,
    brand: BrandsEnum.Porsche,
    shape: {
        borderRadius: 4,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: "none",
                "&:hover": {
                    color: colorCard.primary,
                },
            },
            tetriaryLink: {
                textDecoration: "none",
                "&:hover": {
                    color: colorCard.primary,
                },
            },
            textArea: {
                a: {
                    color: colorCard.primary,
                    textDecoration: "none",
                    "&:active": {
                        color: colorCard.primaryHover,
                    },
                },
                h1: {
                    color: colorCard.primary,
                },
                h2: {
                    color: colorCard.primary,
                },
                h3: {
                    color: colorCard.primary,
                },
                h4: {
                    color: colorCard.primary,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            primaryButton: {
                color: colorCard.cardBackground,
                backgroundColor: colorCard.primary,
                borderColor: colorCard.primary,
                borderRadius: 4,
                fontFamily: GetFontFamily("Porsche Next"),
                "&:hover, &:focus": {
                    border: `2px solid ${colorCard.primaryHover}`,
                    backgroundColor: colorCard.primaryHover,
                    color: colorCard.cardBackground,
                },
            },
            secondaryButton: {
                borderColor: colorCard.primary,
                backgroundColor: colorCard.cardBackground,
                color: colorCard.primary,
                borderRadius: 4,
                fontFamily: GetFontFamily("Porsche Next"),
                "&:hover, &:focus": {
                    backgroundColor: colorCard.tableOutline,
                    color: colorCard.primary,
                },
            },
        },
        USPBlock: {
            headerWrapper: {
                paddingBottom: 20,
            },
            header: {
                paddingBottom: 0,
            }
        },
        CallbackBlock: {
            gdprArea: {
                "& a": {
                    color: colorCard?.bodyText,
                    textDecoration: "underline",
                    "&:hover": {
                        color: colorCard?.gdprText,
                        textDecoration: "underline",
                    },
                },
            },
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    "&:active": {
                        textDecoration: "underline",
                    },
                },
                "& .text-area p a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                },
            },
            fullBackgroundWrapper: {
                background: `linear-gradient(to bottom, ${colorCard.subhead} 0%, ${colorCard.subhead} 100%) no-repeat`,
                "& input[type=text]": {
                    backgroundColor: colorCard.background,
                },
                "& h4": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& .text-area p a": {
                    color: colorCard.background,
                    "&:hover": {
                        color: colorCard.background,
                    },
                },
                "& a": {
                    color: colorCard.background,
                    textDecoration: "underline",
                    "&:hover": {
                        color: colorCard.background,
                        textDecoration: "underline",
                    },
                },
                "& button": {
                    "& span": {
                        color: colorCard.primary,
                    },
                },
                "& .MuiButton-containedPrimary": {
                    backgroundColor: colorCard.background,
                    border: `1px solid ${colorCard.background}`,
                    color: colorCard.primary,
                    "&:hover": {
                        backgroundColor: colorCard.dividerLine,
                        border: `1px solid ${colorCard.secondaryHover}`,
                        color: colorCard.primary,
                    },
                },
                "& .MuiFormLabel-root.Mui-focused": {
                    color: colorCard?.background,
                },
                "& .MuiFormLabel-root.Mui-error": {
                    color: colorCard?.error,
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.subhead,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Porsche Next"),
            },
            icon: {
                display: "block",
                fill: colorCard.primary,
            },
            "&:hover": {
                color: colorCard.wcag,
            },
        },
        MegaMenu: {
            linkIcon: {
                fill: colorCard.label,
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
            "&:hover": {
                color: colorCard.wcag,
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        ArticleTeaser: {
            root: {
                borderRadius: 12,
                border: `1px solid ${colorCard.background3}`,
                backgroundColor: colorCard.background,
                "& .MuiTypography-colorTextSecondary": {
                    color: colorCard.subhead,
                },
            },
            image: {
                borderRadius: "12px 12px 0px 0px",
            },
            link: {
                linkWithIcon: {
                    flexDirection: "row-reverse",
                },
                icon: {
                    display: "block",
                    fill: colorCard.primary,
                    fontSize: "1rem",
                    flexDirection: "row-reverse",
                    marginRight: "8px",
                    marginLeft: "0px",
                },
            },
        },
        ContactUsAreaTabHead: {
            tabsWrapper: {
                [theme.breakpoints.up("sm")]: {
                    borderRadius: 12,
                },
            },
            rotatedDropdownIcon: {
                transform: "rotate(180deg)",
            },
        },
        TeaserFullPage: {
            image: {
                borderRadius: 12,
            },
            root: {
                border: "none",
                backgroundColor: colorCard.background,
                "& .MuiTypography-colorTextSecondary": {
                    color: colorCard.subhead,
                },
            },
            teaserActions: {
                link: {
                    flexDirection: "row-reverse",
                },
                icon: {
                    display: "block",
                    fill: colorCard.primary,
                    fontSize: "1rem",
                    flexDirection: "row-reverse",
                    marginRight: "8px",
                    marginLeft: "0px",
                },
            }
        },
        TextBlock: {
            textBlock: {
                color: colorCard.primary,
                "& .text-area a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    "&:active": {
                        textDecoration: "underline",
                    },
                },
                "& .text-area p a": {
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    "&:active": {
                        textDecoration: "underline",
                    },
                },
            },
            image: {
                borderRadius: 12,
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderRadius: "0px",
            },
            accordionHeader: {
                borderRadius: "0px",
                backgroundColor: colorCard.accordionClosed,
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
                borderRadius: "0px",
            },
        },
        LargeTeaserWithUsp: {
            image: {
                borderRadius: 12,
            },
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                backgroundColor: colorCard.subhead,
            },
            heroWithHeadlineBody: {
                primaryButton: {
                    textDecoration: "none !important",
                    color: colorCard.primary,
                    fontFamily: GetFontFamily("Porsche Next"),
                    backgroundColor: colorCard.background,
                    "&:hover": {
                        border: `1px solid ${colorCard.dividerLine}`,
                        backgroundColor: colorCard.dividerLine,
                        color: colorCard.primary,
                    },
                },
                containedPrimaryButton: {
                    textDecoration: "none !important",
                    color: colorCard.primary,
                    fontFamily: GetFontFamily("Porsche Next"),
                    backgroundColor: colorCard.background,
                    "&:hover": {
                        border: `1px solid ${colorCard.dividerLine}`,
                        backgroundColor: colorCard.dividerLine,
                        color: colorCard.primary,
                    },
                },
                secondaryButton: {
                    textDecoration: "none !important",
                    color: colorCard?.tableBackground,
                    fontFamily: GetFontFamily("Porsche Next"),
                    borderColor: colorCard?.tableBackground,
                    backgroundColor: colorCard.primary,
                    "&:hover": {
                        border: `1px solid ${colorCard.tableBackground}`,
                        backgroundColor: colorCard.primaryHover,
                        color: colorCard.tableBackground,
                    },
                },
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.primary,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                linkWithIcon: {
                    color: colorCard.label,
                    flexDirection: "row-reverse",
                },
                icon: {
                    display: "block",
                    fill: colorCard.primary,
                    fontSize: "1rem",
                    flexDirection: "row-reverse",
                    marginRight: "8px",
                    marginLeft: "0px",
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.primary,
            },
            root: {
                borderRadius: 12,
            },
        },
        CustomizedTextBox: {
            textBoxWrapper: {
                border: `1px solid ${colorCard.inputOutline}`,
            },
            textBoxInput: {
                backgroundColor: colorCard.cardBackground,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        Header: {
            menuBarWrapper: {
                "& .MuiTypography-body1": {
                    color: colorCard.primary,
                },
            },
        },
        Navigation: {
            appmenu: {
                backgroundColor: colorCard.background,
                [theme.breakpoints.down("md")]: {
                    backgroundColor: colorCard.background,
                },
            },
            icon: {
                color: colorCard.primary,
                "& :hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
                "& :active": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
            },
            menuButtonText: {
                fontFamily: GetFontFamily("Porsche Next"),
                color: colorCard.primary,
            },
        },
        Item: {
            link: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Porsche Next"),
                hover: {
                    textDecoration: "none",
                },
            },
            active: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Porsche Next"),
            },
        },
        NavigationItems: {
            mobMenuItem: {
                color: colorCard.primary,
                [theme.breakpoints.down("md")]: {
                    borderBottom: `0px solid ${colorCard.background}`,
                },
                "&.active": {
                    color: colorCard.primary,
                },
            },
            itemWrapper: {
                "&:hover": {
                    backgroundColor: colorCard.dividerLine,
                    textDecoration: "none",
                },
                "& .active": {
                    fontWeight: 600,
                }
            },
            activeItem: {
                color: colorCard.primary,
                textDecoration: "none",
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
            },
            item: {
                "&:hover": {
                    textDecoration: "none",
                },
                "& div": {
                    fontFamily: GetFontFamily("Porsche Next"),
                },
                fontFamily: GetFontFamily("Porsche Next"),
            },
            thirdLevel: {
                color: colorCard.primary,
                fontFamily: GetFontFamily("Porsche Next"),
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
            },
            secondLevel: {
                color: colorCard.primary,
                fontFamily: GetFontFamily("Porsche Next"),
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
            },
            text: {
                color: colorCard.primary,
                "&:hover": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
                "&:active": {
                    color: colorCard.primaryHover,
                    textDecoration: "none",
                },
            },
            wrapper: {
                backgroundColor: colorCard.background,
            }
        },
        FactAndIcon: {
            root: {
                borderRadius: 12,
                border: `1px solid ${colorCard.tableOutline}`,
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
            },
            wrapper: {
                borderBottom: `1px solid ${colorCard?.wcag}`,
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
                backgroundColor: colorCard.primary,
                "& .MuiTypography-h4": {
                    color: colorCard.tableBackground,
                },
                "& .MuiDivider-root": {
                    background: colorCard.wcag,
                },
                "& .MuiLink-root": {
                    color: colorCard.dividerLine,
                    "&:hover": {
                        color: colorCard.dividerLine,
                        textDecoration: "underline",
                    },
                },
            },
            footerNoOverflow: {
                backgroundColor: colorCard.background,
            },
            footerBottomWrapper: {
                backgroundColor: colorCard.primary,
                color: colorCard.tableBackground,
                "& .MuiTypography-body1": {
                    color: colorCard.tableOutline,
                },
                "& .MuiLink-root": {
                    fontFamily: GetFontFamily("Porsche Next"),
                    color: colorCard.tableBackground,
                    "&:hover": {
                        color: colorCard.tableBackground,
                        textDecoration: "underline",
                    },
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
            },
        },
        SubmitButtonElementBlock: {
            submitButton: {
                backgroundColor: colorCard.headlines,
            },
        },
    },
    teaser: {
        root: {
            backgroundColor: colorCard.cardBackground,
            border: true,
            borderColor: colorCard.tableOutline,
        },
        container: {
            backgroundColor: colorCard.background3,
        },
        icon: {
            textIndent: "30px",
            backgroundPosition: "top left"
        }
    },
    webshopEntry: {
        gdprTextColor: colorCard.bodyText,
        backgroundColor: colorCard.background3,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
    },
    header: {
        uspBannerBackground: colorCard.primary,
        backgroundColor: colorCard.background,
        navigationLinkColor: colorCard.label,
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background2,
        backgroundColor: colorCard.background2,
        divider: colorCard.dividerLine,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
        darkModeTextColor: colorCard.background2,
    },
    layout: {
        backgroundColor: colorCard.background,
    },
    accordionBlock: {
        expandedBackgroundColor: colorCard.cardBackground,
        backgroundColor: colorCard.accordionClosed,
    },
    contactUs: {
        backgroundColor: colorCard.dividerLine,
        activeBackgroundColor: colorCard.accordionClosed,
    },
    factAndIcon: {
        backgroundColor: colorCard.cardBackground,
    },
    breadcrumbs: {
        activeColor: colorCard.primary,
        color: colorCard.primary,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background,
        headerColor: colorCard.tableBackground,
    },
    icons: {
        secondaryHelpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Tooltip" },
                React.createElement("path", { id: "Vector", d: "M11 7H13V9H11V7ZM11 11H13V17H11V11Z", fill: "white" }),
                React.createElement("path", { id: "Vector_2", d: "M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.387 3 12 3ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z", fill: "white" })))),
        linkArrowIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Arrow" },
                React.createElement("g", { id: "Vector" },
                    React.createElement("mask", { id: "path-1-inside-1_1837_1306", fill: "white" },
                        React.createElement("path", { d: "M14.81 18L20 11.5L14.81 5H13.56L18.37 11H4V12H18.37L13.56 18H14.81Z" })),
                    React.createElement("path", { d: "M14.81 18L20 11.5L14.81 5H13.56L18.37 11H4V12H18.37L13.56 18H14.81Z", fill: "black" }),
                    React.createElement("path", { d: "M14.81 18V19H15.2912L15.5915 18.624L14.81 18ZM20 11.5L20.7815 12.124L21.2797 11.5L20.7815 10.876L20 11.5ZM14.81 5L15.5915 4.37604L15.2912 4H14.81V5ZM13.56 5V4H11.4767L12.7798 5.62549L13.56 5ZM18.37 11V12H20.4533L19.1502 10.3745L18.37 11ZM4 11V10H3V11H4ZM4 12H3V13H4V12ZM18.37 12L19.1502 12.6255L20.4533 11H18.37V12ZM13.56 18L12.7798 17.3745L11.4767 19H13.56V18ZM15.5915 18.624L20.7815 12.124L19.2185 10.876L14.0285 17.376L15.5915 18.624ZM20.7815 10.876L15.5915 4.37604L14.0285 5.62396L19.2185 12.124L20.7815 10.876ZM14.81 4H13.56V6H14.81V4ZM12.7798 5.62549L17.5898 11.6255L19.1502 10.3745L14.3402 4.37451L12.7798 5.62549ZM18.37 10H4V12H18.37V10ZM3 11V12H5V11H3ZM4 13H18.37V11H4V13ZM17.5898 11.3745L12.7798 17.3745L14.3402 18.6255L19.1502 12.6255L17.5898 11.3745ZM13.56 19H14.81V17H13.56V19Z", fill: "black", mask: "url(#path-1-inside-1_1837_1306)" }))))),
        tooltipCloseIcon: (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close" },
                React.createElement("path", { id: "Vector", d: "M1 15H2.5L8.09 8.83L13.68 15H15.18L8.84 8L15.18 1H13.68L8.09 7.17L2.5 1H1L7.34 8L1 15Z", fill: "#F5F5F5" })))),
        expandIcon: (React.createElement("svg", { width: "25", height: "25", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Plus" },
                React.createElement("g", { id: "Vector" },
                    React.createElement("mask", { id: "path-1-inside-1_26_1941", fill: "white" },
                        React.createElement("path", { d: "M20.3501 11.145L12.3501 11.145V3.14502H11.3501V11.145H3.3501V12.145H11.3501V20.145H12.3501L12.3501 12.145L20.3501 12.145V11.145Z" })),
                    React.createElement("path", { d: "M20.3501 11.145L12.3501 11.145V3.14502H11.3501V11.145H3.3501V12.145H11.3501V20.145H12.3501L12.3501 12.145L20.3501 12.145V11.145Z", fill: "black" }),
                    React.createElement("path", { d: "M20.3501 11.145H21.3501V10.145H20.3501V11.145ZM12.3501 11.145H11.3501V12.145H12.3501V11.145ZM12.3501 3.14502H13.3501V2.14502H12.3501V3.14502ZM11.3501 3.14502V2.14502H10.3501V3.14502H11.3501ZM11.3501 11.145V12.145H12.3501V11.145H11.3501ZM3.3501 11.145V10.145H2.3501V11.145H3.3501ZM3.3501 12.145H2.3501V13.145H3.3501V12.145ZM11.3501 12.145H12.3501V11.145H11.3501V12.145ZM11.3501 20.145H10.3501V21.145H11.3501V20.145ZM12.3501 20.145V21.145H13.3501V20.145H12.3501ZM12.3501 12.145V11.145H11.3501V12.145H12.3501ZM20.3501 12.145V13.145H21.3501V12.145H20.3501ZM20.3501 10.145H12.3501V12.145L20.3501 12.145V10.145ZM13.3501 11.145V3.14502H11.3501V11.145H13.3501ZM12.3501 2.14502H11.3501V4.14502H12.3501V2.14502ZM10.3501 3.14502V11.145H12.3501V3.14502H10.3501ZM11.3501 10.145H3.3501V12.145H11.3501V10.145ZM2.3501 11.145V12.145H4.3501V11.145H2.3501ZM3.3501 13.145H11.3501V11.145H3.3501V13.145ZM10.3501 12.145V20.145H12.3501L12.3501 12.145H10.3501ZM11.3501 21.145H12.3501L12.3501 19.145H11.3501V21.145ZM13.3501 20.145L13.3501 12.145H11.3501V20.145H13.3501ZM12.3501 13.145L20.3501 13.145V11.145L12.3501 11.145V13.145ZM21.3501 12.145V11.145L19.3501 11.145V12.145L21.3501 12.145Z", fill: "black", mask: "url(#path-1-inside-1_26_1941)" }))))),
        expandIconRotation: "45",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Plus" },
                React.createElement("g", { id: "Vector" },
                    React.createElement("mask", { id: "path-1-inside-1_21_593", fill: "white" },
                        React.createElement("path", { d: "M20 11H12V3H11V11H3V12H11L11 20H12L12 12L20 12V11Z" })),
                    React.createElement("path", { d: "M20 11H12V3H11V11H3V12H11L11 20H12L12 12L20 12V11Z", fill: "black" }),
                    React.createElement("path", { d: "M20 11H21V10H20V11ZM12 11H11V12H12V11ZM12 3H13V2H12V3ZM11 3V2H10V3H11ZM11 11V12H12V11H11ZM3 11V10H2V11H3ZM3 12H2V13H3V12ZM11 12H12V11H11V12ZM11 20H10V21H11V20ZM12 20V21H13V20H12ZM12 12V11H11V12H12ZM20 12V13H21V12H20ZM20 10H12V12L20 12V10ZM13 11V3H11V11H13ZM12 2H11V4H12V2ZM10 3V11H12V3H10ZM11 10H3V12H11V10ZM2 11V12H4V11H2ZM3 13H11V11H3V13ZM10 12V20H12L12 12H10ZM11 21H12L12 19H11L11 21ZM13 20L13 12H11L11 20H13ZM12 13L20 13V11H12V13ZM21 12V11H19V12L21 12Z", fill: "black", mask: "url(#path-1-inside-1_21_593)" }))))),
        expandIconRotationNavigation: "45",
        comparisonTableExpand: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Arrow" },
                React.createElement("path", { id: "Vector", d: "M11.7 14.4L12 14.625L12.3 14.4L19.5 9V9.00975L12 14.6254L4.5 9.00975V9L11.7 14.4Z", fill: "black", stroke: "black" })))),
        comparisonTableExpandRotation: "180",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Arrow" },
                React.createElement("path", { id: "Vector", d: "M11.7 14.4L12 14.625L12.3 14.4L19.5 9V9.00975L12 14.6254L4.5 9.00975V9L11.7 14.4Z", fill: "black", stroke: "black" })))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Check" },
                React.createElement("path", { id: "Vector", d: "M17.8482 8.85887L18.2125 8.5054L17.8536 8.14645L17.3536 7.64645L17.0057 7.29864L16.6523 7.6407L10.8057 13.2986L8.35355 10.8464L8 10.4929L7.64645 10.8464L7.14645 11.3464L6.79289 11.7L7.14645 12.0536L10.4464 15.3536L10.7947 15.7018L11.1482 15.3589L17.8482 8.85887ZM20.5 11.5C20.5 15.9239 16.9239 19.5 12.5 19.5C8.07614 19.5 4.5 15.9239 4.5 11.5C4.5 7.07614 8.07614 3.5 12.5 3.5C16.9239 3.5 20.5 7.07614 20.5 11.5Z", fill: "black", stroke: "black" })))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close", opacity: "0.4" },
                React.createElement("path", { id: "Vector", d: "M5 19H6.5L12.09 12.83L17.68 19H19.18L12.84 12L19.18 5H17.68L12.09 11.17L6.5 5H5L11.34 12L5 19Z", fill: "black" })))),
        checkmarkIconCSS: encodeURIComponent(`<svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Check">
          <path
            id="Vector"
            d="M17.8482 8.85887L18.2125 8.5054L17.8536 8.14645L17.3536 7.64645L17.0057 7.29864L16.6523 7.6407L10.8057 13.2986L8.35355 10.8464L8 10.4929L7.64645 10.8464L7.14645 11.3464L6.79289 11.7L7.14645 12.0536L10.4464 15.3536L10.7947 15.7018L11.1482 15.3589L17.8482 8.85887ZM20.5 11.5C20.5 15.9239 16.9239 19.5 12.5 19.5C8.07614 19.5 4.5 15.9239 4.5 11.5C4.5 7.07614 8.07614 3.5 12.5 3.5C16.9239 3.5 20.5 7.07614 20.5 11.5Z"
            fill="black"
            stroke="black"
          />
        </g>
      </svg>
      `),
        heroCheckmarkIconCSS: encodeURIComponent(`
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#FFFFFF" stroke-miterlimit="10"/>
    <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#FFFFFF" stroke-miterlimit="10"/>
    </svg>
    `),
        bulletIconCSS: encodeURIComponent(`

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Check">
<path id="Vector" d="M17.8482 8.85887L18.2125 8.5054L17.8536 8.14645L17.3536 7.64645L17.0057 7.29864L16.6523 7.6407L10.8057 13.2986L8.35355 10.8464L8 10.4929L7.64645 10.8464L7.14645 11.3464L6.79289 11.7L7.14645 12.0536L10.4464 15.3536L10.7947 15.7018L11.1482 15.3589L17.8482 8.85887ZM20.5 11.5C20.5 15.9239 16.9239 19.5 12.5 19.5C8.07614 19.5 4.5 15.9239 4.5 11.5C4.5 7.07614 8.07614 3.5 12.5 3.5C16.9239 3.5 20.5 7.07614 20.5 11.5Z" fill="black" stroke="black"/>
</g>
</svg>
    `),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close", opacity: "0.4" },
                React.createElement("path", { id: "Vector", d: "M5 19H6.5L12.09 12.83L17.68 19H19.18L12.84 12L19.18 5H17.68L12.09 11.17L6.5 5H5L11.34 12L5 19Z", fill: "black" })))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11 7H13V9H11V7ZM11 11H13V17H11V11Z", fill: "black" }),
            React.createElement("path", { d: "M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.387 3 12 3ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z", fill: "black" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Porsche Next"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Porsche Next"),
        },
        h1: {
            fontFamily: GetFontFamily("Porsche Next"),
            fontSize: "2.5rem",
            fontWeight: 600,
            lineHeight: "3rem",
            color: colorCard?.primary,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Porsche Next"),
            fontSize: "2rem",
            lineHeight: "2.5rem",
            color: colorCard?.primary,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.75rem",
                lineHeight: "2.25rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Porsche Next"),
            fontSize: "1.5rem",
            lineHeight: "2rem",
            color: colorCard?.primary,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Porsche Next"),
            color: colorCard?.primary,
            "word-break": "break-word",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.125rem",
                lineHeight: "1.625rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Porsche Next"),
            color: colorCard?.primary,
            fontSize: "1.25rem",
            lineHeight: "1.625rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Porsche Next"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Porsche Next"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText, //bodyText??
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.primary, //?
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        MuiInputBase: {
            root: {
                color: colorCard.primary,
            },
        },
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Porsche Next"),
            },
            colorTextSecondary: {
                color: colorCard.primary,
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.primary,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                        textDecoration: "none",
                    },
                    "&:active": {
                        color: colorCard?.primaryHover,
                        textDecoration: "none",
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "4px",
                border: "none",
                backgroundColor: colorCard.accordionClosed,
            },
            input: {
                borderRadius: "4px",
                backgroundColor: colorCard.cardBackground,
                borderBottom: "none",
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.primary,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 4,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 4,
                "text-transform": "none",
            },
            label: {
                "text-align": "center",
            },
            outlined: {
                color: colorCard?.cardBackground,
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard.primaryHover,
                    color: colorCard.cardBackground,
                },
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                backgroundColor: colorCard?.tableBackground,
                "&:hover": {
                    backgroundColor: colorCard?.secondaryHover,
                    color: colorCard.primary,
                },
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                color: colorCard?.tableBackground,
                "&:hover": {
                    border: `2px solid ${colorCard.primaryHover}`,
                    backgroundColor: colorCard.primaryHover,
                    color: colorCard.tableBackground,
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        border: `2px solid ${colorCard.primaryHover}`,
                        backgroundColor: colorCard.primaryHover,
                        color: colorCard.cardBackground,
                    },
                    "&:active": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.cardBackground,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(porsche);
