import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#EA5D1A",
    secondary: "#102B4E",
    tertiary: "#102B4E",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#F5F5F5",
    background2: "#FAFAF9",
    accordionClosed: "#FAFAF9",
    tableOutline: "#DEDAD4",
    dividerLine: "#DEDAD4",
    secondaryHover: "#6F6F6F",
    background3: "#DEDAD4",
    wcagOutline: "#6F6F6F",
    inputOutline: "#6F6F6F",
    placeholderText: "#6F6F6F",
    wcag: "#3F3F3F",
    gdprText: "#3F3F3F",
    helperText: "#3F3F3F",
    primaryHover: "#F4AF8E",
    tetriaryHover: "#6F6F6F",
    subhead: "#33302E",
    bodyText: "#33302E",
    headlines: "#0F0F0F",
    label: "#0F0F0F",
    inputText: "#141414",
    success: "#17582E",
    successBackground: "#DAE4DD",
    info: "#1E369B",
    infoBackground: "#DBDFEF",
    warning: "#FFE668",
    warningBackground: "#FFFBE7",
    error: "#FF0012",
    errorBackground: "#FFD6D9",
};
const fontStandart = GetFontFamily("SEAT Bcn");
// More properties here: https://material-ui.com/customization/default-theme/
export const seatmo = {
    themeName: "seatmo",
    colorCard: colorCard,
    brand: BrandsEnum.SeatMO,
    shape: {
        borderRadius: 24,
    },
    customStyling: {
        global: {
            secondaryButton: {
                fontFamily: fontStandart,
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                borderRadius: 4,
                border: `2px solid ${colorCard?.label}`,
                background: colorCard?.background,
                color: colorCard?.primary || theme.palette?.primary?.main,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&:hover, &:focus": {
                    border: `2px solid ${colorCard?.secondaryHover}`,
                    color: colorCard?.secondaryHover,
                    backgroundColor: colorCard?.background,
                    textDecoration: "none",
                },
            },
            tetriaryButton: {
                fontWeight: 700
            },
            primaryButton: {
                fontFamily: fontStandart,
                borderRadius: 24,
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                marginTop: 8,
                border: `1px solid ${colorCard?.primary || theme.palette?.primary?.main}`,
                background: colorCard?.primary || theme.palette?.primary?.main,
                color: colorCard?.background || theme.palette?.primary?.contrastText,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&:hover, &:focus": {
                    backgroundColor: colorCard?.primaryHover || theme.palette?.primary?.dark,
                    border: `1px solid ${colorCard?.primaryHover || theme.palette?.primary?.dark}`,
                    textDecoration: "none",
                    color: colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                },
                "& a": {
                    textDecoration: "none",
                    color: colorCard.background,
                    "&:hover": {
                        textDecoration: "none",
                        color: colorCard.background,
                    },
                },
            },
            textArea: {
                a: {
                    color: colorCard.bodyText,
                    "&:hover": {
                        color: colorCard.bodyText,
                    },
                    "&:active": {
                        color: colorCard.bodyText,
                    },
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
        },
        MegaMenu: {
            rootLink: {
                color: colorCard.bodyText,
                fontWeight: 500
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontWeight: 500
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
                fontWeight: 500
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.accordionClosed}`,
                borderBottom: `1px solid ${colorCard.accordionClosed}`,
                backgroundColor: colorCard.accordionClosed
            },
            accordionHeader: {
                backgroundColor: colorCard.background2
            }
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
        },
        ContactUsAreaTabHead: {
            tabsWrapper: {
                border: `1px solid ${colorCard?.dividerLine}`,
                borderRadius: 4
            },
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
            headerText: {
                fontWeight: 700,
            },
            activeHeaderText: {
                fontWeight: 700,
            },
            expandButtonContainer: {
                paddingRight: 16,
            }
        },
        CustomizedTextBoxTooltip: {
            tooltipWidth: {
                border: `1px solid ${colorCard.background3}`,
                backgroundColor: colorCard.background
            },
            tooltipHeader: {
                color: colorCard.headlines,
                fontWeight: 700
            },
            tooltipBody: {
                color: colorCard.bodyText,
            }
        },
        NavigationItems: {
            activeItem: {
                color: colorCard.subhead,
                fontWeight: 500
            },
            secondLevel: {
                fontWeight: 500
            },
            wrapper: {
                backgroundColor: colorCard.background2
            }
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area p": {
                    color: colorCard.gdprText,
                    fontWeight: 400,
                },
                "& .text-area p a": {
                    color: colorCard.gdprText,
                    fontWeight: 400,
                },
                [theme.breakpoints.up("md")]: {
                    marginTop: 16
                }
            },
            webshopGrid: {},
            withoutBackground: {
                fontWeight: 500
            }
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.bodyText,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.bodyText,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.bodyText,
            },
            darkModeHover: colorCard.accordionClosed,
        },
        CustomizedTextBox: {
            textBoxInput: {
                background: colorCard.accordionClosed,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
                padding: "14px 12px 10px",
                fontWeight: 500
            },
        },
        factAndIcon: {
            header: {
                color: colorCard?.bodyText,
            },
        },
        BreadCrumbs: {
            currentLink: {
                fontWeight: 700
            },
            wrapper: {
                backgroundColor: colorCard.background2,
            },
            innerWrapper: {
                backgroundColor: colorCard.background2,
            },
        },
        BraviContentPage: {
            heroWithHeadlineBody: {
                primaryButton: {
                    backgroundColor: colorCard.background,
                    borderRadius: 4,
                    "&:hover": {
                        border: `1px solid #999999`,
                        backgroundColor: "#999999",
                        color: colorCard.subhead,
                    },
                },
            },
            heroWithHeadlineWrapper: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                    },
                },
            },
        },
        MOStartPage: {
            heroWithHeadline: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& button": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                    },
                },
            },
        },
        TeaserFullPage: {
            root: {
                backgroundColor: colorCard.background2
            }
        },
        USPBlock: {
            headerWrapper: {
                marginBottom: 20
            },
            header: {
                paddingBottom: 0
            },
            text: {
                fontSize: 16
            }
        },
        Footer: {
            aboutFooterText: {
                color: colorCard.background,
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.wcag}`,
                borderBottom: `1px solid ${colorCard?.wcag}`,
                background: colorCard.subhead,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiLink-root": {
                    color: colorCard.accordionClosed,
                    "&:hover": {
                        color: colorCard.tableOutline,
                    },
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.accordionClosed
                },
                "& hr": {
                    background: colorCard.wcag
                }
            },
            footerBottomWrapper: {
                background: colorCard.subhead,
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            footerTextWrapper: {
                alignItems: "flex-start"
            },
            bottomLinkWrapper: {
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
            }
        },
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background,
        divider: colorCard.background3,
    },
    breadcrumbs: {
        activeColor: colorCard.label,
        color: colorCard.label,
        iconColor: colorCard.primary,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.bodyText,
        headerColor: colorCard.background,
        textColor: colorCard.background,
    },
    header: {
        uspBannerBackground: colorCard.label,
        uspBannerTextColor: colorCard.background,
        navigationLinkColor: colorCard.bodyText,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.bodyText,
        textColor: colorCard.background,
        body: {
            button: {
                primary: {
                    color: colorCard.bodyText,
                    backgroundColor: colorCard.background,
                    borderColor: colorCard.secondary,
                },
                secondary: {
                    color: colorCard.background,
                    borderColor: colorCard.background,
                    backgroundColor: "transparent",
                },
            },
        },
    },
    webshopEntry: {
        backgroundColor: colorCard.background3,
        inputFieldColor: colorCard.background,
    },
    contactUs: {
        activeBackgroundColor: colorCard.background3,
        backgroundColor: colorCard.background2,
        activeHeaderColor: colorCard.label,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.tertiary,
    },
    link: {
        showArrow: false,
    },
    teaser: {
        icon: {
            textIndent: "0px",
            paddingLeft: "30px",
            backgroundPosition: "0 0px"
        }
    },
    icons: {
        comparisonTableExpand: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M17.1183 8.57144L12.858 12.8088L8.59678 8.57144L8.59493 8.57144L7.28564 9.87532L12.858 15.4286L18.4285 9.87532L17.1201 8.57144L17.1183 8.57144Z", fill: "#0F0F0F" }))),
        comparisonTableExpandRotation: "180",
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.7717 11.2151V4.284H10.5526V11.2151H3.65845V13.4343H10.5526V20.3654H12.7717V13.4343H19.6584V11.2151H12.7717Z", fill: "#0F0F0F" }))),
        expandIconRotation: "45",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.7717 11.2151V4.284H10.5526V11.2151H3.65845V13.4343H10.5526V20.3654H12.7717V13.4343H19.6584V11.2151H12.7717Z", fill: "#0F0F0F" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.7717 11.2151V4.284H10.5526V11.2151H3.65845V13.4343H10.5526V20.3654H12.7717V13.4343H19.6584V11.2151H12.7717Z", fill: "#0F0F0F" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.9983 22C17.511 22 21.9967 17.5143 21.9967 12.0017C21.9967 9.71366 21.244 7.55946 19.819 5.77321L19.3373 6.81017C20.4178 8.33216 20.9931 10.1151 20.9931 12.0017C20.9931 16.9624 16.959 20.9965 11.9983 20.9965C7.03763 20.9965 3.00351 16.959 3.00351 11.9983C3.00351 7.03763 7.03763 3.00351 11.9983 3.00351C13.6575 3.00351 15.2631 3.45509 16.668 4.30808L11.9114 14.487L8.99783 10.299L8.17495 10.871L12.0853 16.4874L17.9458 3.94681L17.5912 3.70597C15.9354 2.59207 14.002 2 11.9983 2C6.4857 2 2 6.4857 2 11.9983C2 17.511 6.4857 21.9967 11.9983 21.9967V22Z", fill: "#0F0F0F" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2462 11.7106L11.623 12.3338L16.0117 16.7224L16.6348 16.0993L12.2462 11.7106Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M7.85555 7.32123L7.23242 7.94437L11.6211 12.333L12.2442 11.7099L7.85555 7.32123Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M16.0058 7.32279L11.6172 11.7114L12.2403 12.3346L16.629 7.94593L16.0058 7.32279Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M11.6164 11.7091L7.22778 16.0978L7.85092 16.7209L12.2395 12.3323L11.6164 11.7091Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M12 22C6.48594 22 2 17.5141 2 12C2 6.48594 6.48594 2 12 2C17.5141 2 22 6.48594 22 12C22 12.5623 21.9538 13.1246 21.8615 13.6744L20.9929 13.5275C21.0768 13.0239 21.1188 12.512 21.1188 12C21.1188 6.97272 17.0273 2.88124 12 2.88124C6.97272 2.88124 2.88124 6.97272 2.88124 12C2.88124 17.0273 6.97272 21.1188 12 21.1188C15.483 21.1188 18.6093 19.18 20.1662 16.0663L20.9551 16.4608C19.2514 19.8808 15.8187 22.0042 12 22.0042V22Z", fill: "#0F0F0F" }))),
        tooltipCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2462 11.7106L11.623 12.3338L16.0117 16.7224L16.6348 16.0993L12.2462 11.7106Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M7.85555 7.32123L7.23242 7.94437L11.6211 12.333L12.2442 11.7099L7.85555 7.32123Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M16.0058 7.32279L11.6172 11.7114L12.2403 12.3346L16.629 7.94593L16.0058 7.32279Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M11.6164 11.7091L7.22778 16.0978L7.85092 16.7209L12.2395 12.3323L11.6164 11.7091Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M12 22C6.48594 22 2 17.5141 2 12C2 6.48594 6.48594 2 12 2C17.5141 2 22 6.48594 22 12C22 12.5623 21.9538 13.1246 21.8615 13.6744L20.9929 13.5275C21.0768 13.0239 21.1188 12.512 21.1188 12C21.1188 6.97272 17.0273 2.88124 12 2.88124C6.97272 2.88124 2.88124 6.97272 2.88124 12C2.88124 17.0273 6.97272 21.1188 12 21.1188C15.483 21.1188 18.6093 19.18 20.1662 16.0663L20.9551 16.4608C19.2514 19.8808 15.8187 22.0042 12 22.0042V22Z", fill: "#0F0F0F" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9983 22C17.511 22 21.9967 17.5143 21.9967 12.0017C21.9967 9.71366 21.244 7.55946 19.819 5.77321L19.3373 6.81017C20.4178 8.33216 20.9931 10.1151 20.9931 12.0017C20.9931 16.9624 16.959 20.9965 11.9983 20.9965C7.03763 20.9965 3.00351 16.959 3.00351 11.9983C3.00351 7.03763 7.03763 3.00351 11.9983 3.00351C13.6575 3.00351 15.2631 3.45509 16.668 4.30808L11.9114 14.487L8.99783 10.299L8.17495 10.871L12.0853 16.4874L17.9458 3.94681L17.5912 3.70597C15.9354 2.59207 14.002 2 11.9983 2C6.4857 2 2 6.4857 2 11.9983C2 17.511 6.4857 21.9967 11.9983 21.9967V22Z" fill="#0F0F0F" />
      </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9983 22C17.511 22 21.9967 17.5143 21.9967 12.0017C21.9967 9.71366 21.244 7.55946 19.819 5.77321L19.3373 6.81017C20.4178 8.33216 20.9931 10.1151 20.9931 12.0017C20.9931 16.9624 16.959 20.9965 11.9983 20.9965C7.03763 20.9965 3.00351 16.959 3.00351 11.9983C3.00351 7.03763 7.03763 3.00351 11.9983 3.00351C13.6575 3.00351 15.2631 3.45509 16.668 4.30808L11.9114 14.487L8.99783 10.299L8.17495 10.871L12.0853 16.4874L17.9458 3.94681L17.5912 3.70597C15.9354 2.59207 14.002 2 11.9983 2C6.4857 2 2 6.4857 2 11.9983C2 17.511 6.4857 21.9967 11.9983 21.9967V22Z" fill="white" />
      </svg>`),
        bulletIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#102B4E" stroke-miterlimit="10"/>
      <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#102B4E" stroke-miterlimit="10"/>
      </svg>`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2462 11.7107L11.623 12.3338L16.0117 16.7224L16.6348 16.0993L12.2462 11.7107Z", fill: "#999999" }),
            React.createElement("path", { d: "M7.85555 7.3212L7.23242 7.94434L11.6211 12.333L12.2442 11.7098L7.85555 7.3212Z", fill: "#999999" }),
            React.createElement("path", { d: "M16.0063 7.32273L11.6177 11.7114L12.2408 12.3345L16.6294 7.94587L16.0063 7.32273Z", fill: "#999999" }),
            React.createElement("path", { d: "M11.6167 11.7091L7.22803 16.0977L7.85116 16.7208L12.2398 12.3322L11.6167 11.7091Z", fill: "#999999" }),
            React.createElement("path", { d: "M12 22C6.48594 22 2 17.5141 2 12C2 6.48594 6.48594 2 12 2C17.5141 2 22 6.48594 22 12C22 12.5623 21.9538 13.1246 21.8615 13.6744L20.9929 13.5275C21.0768 13.0239 21.1188 12.512 21.1188 12C21.1188 6.97272 17.0273 2.88124 12 2.88124C6.97272 2.88124 2.88124 6.97272 2.88124 12C2.88124 17.0273 6.97272 21.1188 12 21.1188C15.483 21.1188 18.6093 19.18 20.1662 16.0663L20.9551 16.4608C19.2514 19.8808 15.8187 22.0042 12 22.0042V22Z", fill: "#999999" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.0015 2C6.48567 2 2 6.48567 2 12.0015C2 17.5174 6.48567 22.0031 12.0015 22.0031C17.5174 22.0031 22.0031 17.5174 22.0031 12.0015C22.0031 6.48567 17.5143 2 12.0015 2ZM12.0015 21.0802C6.99463 21.0802 2.91982 17.0054 2.91982 11.9985C2.91982 6.99157 6.99463 2.91982 12.0015 2.91982C17.0084 2.91982 21.0832 6.99463 21.0832 12.0015C21.0832 17.0084 17.0084 21.0832 12.0015 21.0832V21.0802Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M12.0014 9.0029C12.8753 9.0029 13.5896 8.29157 13.5896 7.41774C13.5896 6.54391 12.8783 5.83258 12.0014 5.83258C11.1245 5.83258 10.4163 6.54391 10.4163 7.41774C10.4163 8.29157 11.1276 9.0029 12.0014 9.0029ZM12.0014 6.74934C12.3693 6.74934 12.6698 7.04981 12.6698 7.41774C12.6698 7.78567 12.3693 8.08614 12.0014 8.08614C11.6335 8.08614 11.3361 7.78567 11.3361 7.41774C11.3361 7.04981 11.6366 6.74934 12.0014 6.74934Z", fill: "#0F0F0F" }),
            React.createElement("path", { d: "M11.6151 9.70197H10.6187V16.6681C10.6187 17.64 11.4097 18.4311 12.3816 18.4311H13.3781V17.5113H12.4583H12.3816C11.9156 17.5113 11.5385 17.1341 11.5385 16.6681V10.6218H11.6151C12.0812 10.6218 12.4583 10.9989 12.4583 11.465V16.1131H13.3781V11.465C13.3781 10.493 12.5871 9.70197 11.6151 9.70197Z", fill: "#0F0F0F" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: fontStandart,
        color: colorCard?.primary,
        root: {
            fontFamily: fontStandart,
        },
        h1: {
            fontFamily: fontStandart,
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: fontStandart,
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: fontStandart,
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: fontStandart,
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: fontStandart,
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: fontStandart,
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: fontStandart,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: fontStandart,
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.label,
                    fontFamily: fontStandart,
                    "&:hover": {
                        color: colorCard?.tetriaryHover,
                        textDecoration: "none",
                    },
                    "&:active": {
                        color: colorCard?.tetriaryHover,
                    },
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard?.headlines,
                    marginTop: -4,
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.tableOutline,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
                border: `1px solid ${colorCard.tableOutline}`,
                backgroundColor: colorCard?.tableOutline,
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                border: `none`,
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
                background: "transparent",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.background3,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 4,
                "text-transform": "none",
                "text-align": "center",
            },
            outlined: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                backgroundColor: colorCard.background,
                borderWidth: 2,
                "&:hover": {
                    backgroundColor: colorCard.background,
                    borderColor: colorCard?.primaryHover,
                    color: colorCard?.primaryHover,
                },
            },
            outlinedPrimary: {
                color: colorCard?.label,
                backgroundColor: colorCard?.background,
                borderColor: colorCard?.label,
                borderWidth: 2,
                "&:hover": {
                    color: colorCard?.secondaryHover,
                    backgroundColor: colorCard?.background,
                    borderColor: colorCard?.secondaryHover,
                    borderWidth: 2,
                },
            },
            containedPrimary: {
                color: colorCard?.background,
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.primaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(seatmo);
