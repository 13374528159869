import { BrandsEnum } from "../../enums";
export const useBrandNavigationHighligher = () => {
    function handleLinkHighlight(item, brand) {
        //some brands may have specific cases for highlighting navigation items.
        //volvia have sub brands under same domain so it complicates things
        //we need to highlight foretag node for links under /foretag/xxx
        const currentUrl = window.location.href;
        const itemUrl = item.href;
        if (brand == BrandsEnum.Volvia ||
            brand == BrandsEnum.VolviaForetag ||
            brand == BrandsEnum.VolviaLastbilar) {
            //highlight if link has same root as navigation main link
            if (!itemUrl.includes("/lastbilar") && !itemUrl.includes("/foretag")) {
                // Case: Volvia privat node
                return (!currentUrl.includes("/lastbilar") && !currentUrl.includes("/foretag"));
            }
            else {
                // Cases: Volvia lastbilar/foretag node
                return currentUrl.includes(item.href);
            }
        }
        if (brand === BrandsEnum.Renault ||
            brand === BrandsEnum.RenaultForetag) {
            //highlight if link has same root as navigation main link
            if (!itemUrl.includes("/foretag")) {
                // Case: Renault privat node
                return !currentUrl.includes("/foretag");
            }
            else {
                // Case: Renault foretag node
                return currentUrl.includes(item.href);
            }
        }
        //other brands for now don't have any specific private & commercial setup
        return true;
    }
    return {
        handleLinkHighlight,
    };
};
export default useBrandNavigationHighligher;
