//Referenced components for configuration
import Loader from "app/Components/Shared/Loader";
import MOEntry from "app/Components/Shared/MOEntry";
// import { SettingsInitialization as SettingsModule } from '@episerver/foundation-settings';
const appDebug = false; //process.env.NODE_ENV === 'development';
//Website configuration
export const Config = {
    //Main setup
    enableDebug: false,
    autoExpandRequests: true,
    //Connection details
    basePath: process.env.WEB_PATH ?? "",
    epiBaseUrl: window.location.origin.indexOf("http") >= 0 &&
        window.location.port !== "9000"
        ? window.location.origin + "/"
        : process.env.EPI_CMS_URL,
    defaultLanguage: "sv",
    //Site layout (i.e. wrapper for routed IContent)
    layout: MOEntry,
    //Spinner
    enableSpinner: true,
    spinner: Loader,
    spinnerTimeout: 250,
    //Content area configuration
    contentArea: {
        displayOptions: {
            "displaymode-screen": "screen v-100",
            "displaymode-half": "col-12 col-md-6",
            "displaymode-one-third": "col-12 col-md-4",
            "displaymode-two-items": "col-6",
            "displaymode-three-items": "col-4",
        },
        defaultRowClass: "row no-gutters",
        defaultBlockClass: "col-12",
        defaultContainerClass: "container",
        containerBreakBlockClass: "displaymode-screen",
    },
    //Preloading of components
    preLoadComponents: [
        // "Media/Image/ImageMediaData",
        // "Block/Media/Image/ImageMediaData",
        // "Block/MenuItemBlock",
        // "Block/TextBlock",
        // "Block/BreadcrumbBlock",
        // "Page/StartPage",
        // "Page/LandingPage",
        // "Page/StandardPage"
        // "Page/BraviStartPage",
        // "Shared/Header",
        // "Shared/Footer",
        // "app/Components/Shared/MOLayout",
        // "app/Components/Shared/MetaTags",
        "app/Components/Shared/Header",
        "app/Components/Shared/Footer",
        "app/Components/Navigation/Navigation",
        "app/Components/Navigation/NavigationItems",
        "app/Components/Navigation/USPBanner",
        "app/Components/Block/ButtonBlock",
        "app/Components/Navigation/USPBanner",
        "app/Components/Page/BraviStartPage",
        "app/Components/Page/BraviContentPage",
        "app/Components/Page/BraviWebShopPage",
        "app/Components/Block/WebshopEntryBlock",
        "app/Components/Block/SmallTeaserArticleContainerBlock",
        "app/Components/Block/FooterLinksBlock",
        "app/Components/LinkWithArrow",
        "app/Components/Block/LargeUSPTeaserBlock",
        "app/Components/Block/USPContainerBlock",
        "app/Components/Block/USPLineContainer",
        "app/Components/Block/WarrantyBlock",
        "app/Components/Block/USPLineBlock",
        "app/Components/Block/TeaserFullPageBlock",
        "app/Components/Block/ArticleTeaserBlock",
        "app/Components/Block/MobileAppBlock",
        "app/Components/Block/AccordionBlockContainer",
        "app/Components/Block/AccordionBlock",
        "app/Components/Block/AlertBlock",
        "app/Components/Block/ContactUsInfoContainerBlock",
        "app/Components/Block/GreenCardBlock",
        "app/Components/Block/TextBlock",
        "app/Components/Block/RawBlock",
        "app/Components/Block/ServiceWebshopEntryBlock",
        "app/Components/Block/AlertBlock",
        "app/Components/Block/ContentBoxBlock",
        "app/Components/Block/InfoContainerBlock",
        "app/Components/Block/LinkWithIconBlock",
    ],
    // iContentRepository: {
    //     debug: appDebug,
    //     policy: ContentDelivery.IRepositoryPolicy.LocalStorageFirst
    // },
    // modules: [
    //     new SettingsModule()
    // ],
    iContentDelivery: {
        Debug: false,
        AutoExpandAll: true,
    },
};
export default Config;
