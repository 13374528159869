import React from "react";
import { ListItem, makeStyles, useTheme } from "@material-ui/core";
import { useLocation } from "react-router";
const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none",
        margin: 0,
        fontFamily: theme.typography.fontFamily,
        color: theme.header?.navigationLinkColor || theme.colorCard?.primary,
        "& :hover": {
            textDecoration: "underline",
            ...theme.customStyling?.Item?.link?.hover,
        },
        [theme.breakpoints.down("sm")]: {
            lineHeight: "36px",
            padding: "8px 16px",
            borderBottom: theme.colorCard?.dividerLine || theme.navigation?.divider,
        },
        ...theme.customStyling?.Item?.link,
    },
    active: {
        textDecoration: "underline",
        textDecorationColor: theme.colorCard?.inputText || theme.palette?.text?.primary,
        fontFamily: theme.typography.fontFamily,
        color: theme.header?.navigationLinkColor || theme.colorCard?.primary,
        [theme.breakpoints.down("sm")]: {
            lineHeight: "36px",
            padding: "8px 16px",
        },
        ...theme.customStyling?.Item?.active,
    },
}));
const Item = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { pathname } = useLocation();
    let active = pathname === props.link;
    return props.link.startsWith("http" || "https") ? (React.createElement(ListItem, { disableGutters: true, className: props.className, children: props.children, component: 'a', href: props.link })) : (React.createElement(ListItem, { disableGutters: true, className: active ? classes.active : classes.link, children: props.children, component: 'a', href: props.link }));
};
export default Item;
