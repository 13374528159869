import React, { createContext, useContext, useEffect, useState } from "react";
export const NorwayBankIDAuthContext = createContext({});
export const useAuth = () => useContext(NorwayBankIDAuthContext);
export const NorwayBankIDAuthProvider = ({ children }) => {
    const [claims, setClaims] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [givenName, setGivenName] = useState("");
    const [givenSSN, setGivenSSN] = useState([]);
    const getUser = async () => {
        const response = await fetch("/api/account/volviano/getUser");
        const json = (await response.json());
        console.log("Fetched user data from client as part of BankID process", json);
        setIsAuthenticated(json.isAuthenticated);
        setIsLoading(false);
        if (json.isAuthenticated) {
            setGivenName(json.givenName);
            setGivenSSN(json.givenSSN);
            setClaims(json.claims);
        }
    };
    useEffect(() => {
        getUser();
    }, []);
    const login = () => {
        console.log('Redirecting as login context button click');
        window.location.href = "/api/account/VolviaNO/signin?returnUrl=" + window.location;
    };
    return (React.createElement(NorwayBankIDAuthContext.Provider, { value: {
            isAuthenticated,
            givenName,
            givenSSN,
            claims,
            isLoading,
            login
        } }, children));
};
export default NorwayBankIDAuthProvider;
export const useNorwayBankIDContext = () => {
    const context = React.useContext(NorwayBankIDAuthContext);
    if (context === undefined) {
        throw new Error("use Norway Bank Context should be used inside NorwayBankIDAuthProvider");
    }
    return context;
};
