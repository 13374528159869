import { Grid, SvgIcon, makeStyles, useTheme, } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FetchSearchResults } from "./Service/SearchService";
import { usePageContext } from "./Context/PageContextProvider";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSearchResultsContext } from "./Context/SearchResultsContext";
const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        width: "85px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: 400,
        marginRight: "24px",
        [theme.breakpoints.up("sm")]: {
            width: "80px",
        },
        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
    },
    searchBoxContainerWide: {
        width: "400px",
        marginTop: "12px",
        marginBottom: "12px",
        border: "1px solid #7F7F7F",
        paddingLeft: "8px",
        marginRight: "0px",
        [theme.breakpoints.down("md")]: {
            marginRight: "24px",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            paddingLeft: "10px",
            width: "100%",
        },
    },
    searchGrid: {
        height: "24px",
    },
    searchIcon: (props) => ({
        width: "24px",
        height: "24px",
        paddingRight: props.isOpen ? "5px" : "0px",
        "&:hover": {
            cursor: "pointer",
        },
    }),
    closeIcon: {
        width: "24px",
        height: "24px",
        paddingRight: "8px",
    },
    inputGrid: {
        width: "100%",
        height: "24px",
    },
    input: {
        textAlign: "start",
        width: "100%",
        paddingLeft: "8px",
        height: "24px",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        color: "#323537",
        "&::-ms-clear": {
            display: "none",
            width: 0,
            height: 0,
        },
        fontFamily: theme.typography.fontFamily,
        "&::-webkit-search-cancel-button": {
            "-webkit-appearance": "none",
        },
        "&::placeholder": {
            color: "#323537",
            opacity: 1,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "4px",
        },
    },
}));
const SearchBox = (props) => {
    const theme = useTheme();
    const pageContext = usePageContext();
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles({ isOpen });
    const history = useHistory();
    const searchContext = useSearchResultsContext();
    const [SearchResultsContext, setSearchResultsContext] = useState(searchContext);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const { startPageData } = usePageContext();
    useEffect(() => {
        setSearchResultsContext(SearchResultsContext);
    }, [SearchResultsContext.query]);
    useEffect(() => {
        isOpen ? props.onSearchBoxOpen(isOpen) : props.onSearchBoxClose(isOpen);
    }, [isOpen]);
    const performSearch = async (searchQuery) => {
        const siteId = pageContext.startPageData.contentLink.id;
        const searchResults = await FetchSearchResults(searchQuery, `${siteId}`, 5, theme.brand);
        return searchResults;
    };
    function goToSearchResultsPage() {
        let pathToSearchResultsPage = "/sokresultat";
        if (startPageData &&
            startPageData.searchResultsPageReference &&
            startPageData.searchResultsPageReference.value) {
            const absolutePath = startPageData.searchResultsPageReference.value.toString();
            pathToSearchResultsPage = getSearchResultsPath(absolutePath);
        }
        function getSearchResultsPath(absolutePath) {
            const actualPathToSearchResultsPage = new URL(absolutePath).pathname;
            let webshopPath = pathToSearchResultsPage !== actualPathToSearchResultsPage
                ? actualPathToSearchResultsPage
                : pathToSearchResultsPage;
            // Ensure the search query is URL-safe
            const encodedSearchQuery = encodeURIComponent(inputValue);
            webshopPath += `?q=${encodedSearchQuery}`;
            return webshopPath;
        }
        history.push(pathToSearchResultsPage);
    }
    const handleCloseIconClick = () => {
        // Clear the search query
        setInputValue("");
        setIsOpen(false);
    };
    const handleEnterKeyDown = async (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const inputValue = event.target.value;
            setInputValue(inputValue);
            if (inputValue) {
                searchContext.updateSearchQuery(inputValue);
                const results = await performSearch(inputValue);
                if (results) {
                    searchContext.updateSearchResults(results);
                }
                goToSearchResultsPage();
            }
        }
    };
    const handleOnFocus = () => {
        setIsOpen(true);
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        // fetchAutocompleteOptions(newInputValue);
    };
    // const fetchAutocompleteOptions = async (inputValue: string) => {
    //   if (!inputValue || inputValue.length < 3) {
    //     setSuggestions([]);
    //     return;
    //   }
    //   const response = await FetchAutocompleteSuggestions(inputValue);
    //   const data = await response.json();
    //   setSuggestions(data.suggestions);
    // };
    return (React.createElement(Grid, { container: true, className: clsx(classes.searchBoxContainer, isOpen && classes.searchBoxContainerWide) },
        React.createElement(Grid, { item: true, className: classes.searchGrid },
            React.createElement(SvgIcon, { onClick: () => setIsOpen(true), className: classes.searchIcon }, theme.icons?.searchIcon)),
        React.createElement(Grid, { item: true, className: classes.inputGrid },
            React.createElement("input", { type: "search", placeholder: props.placeholder, className: classes.input, onKeyDown: (event) => {
                    handleEnterKeyDown(event);
                }, onFocus: handleOnFocus, onChange: (event) => handleInputChange(event, event.target.value), value: inputValue })),
        isOpen && (React.createElement(Grid, { item: true },
            React.createElement(SvgIcon, { onClick: handleCloseIconClick, className: classes.closeIcon }, theme.icons?.closeIcon)))));
};
export default SearchBox;
