export function PrepareProperties(variables) {
    return {
        initialWidth: "xl",
        buttons: {
            boxShadow: "none",
        },
        inputs: {
            placeholder: {
                color: variables.colors.placeholder,
                opacity: 1,
            },
        },
        listItems: {
            root: {
                border: "1px solid " + variables.colors.transparent,
                borderLeftWidth: 2,
                "&$selected": {
                    borderLeftColor: variables.colors.primary.main,
                },
            },
        },
    };
}
export function GetFontFamily(mainFont) {
    return [mainFont ?? "", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",");
}
