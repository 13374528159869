export const FontLoader = (brand) => {
    if (typeof document !== "undefined" &&
        document &&
        document.createElement &&
        document.querySelectorAll) {
        const href = "/static/fonts/" + brand + "/index.css";
        const links = document.querySelectorAll('link[href="' + href + '"]');
        if (links.length > 0)
            return;
        const fonts = document.createElement("link");
        fonts.setAttribute("href", href);
        fonts.setAttribute("rel", "stylesheet");
        fonts.setAttribute("type", "text/css");
        document.head.appendChild(fonts);
    }
};
export default FontLoader;
